import React, { useCallback, useEffect } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";

import { Paper, Typography } from "@mui/material";

import SignUpComponent from "../../components/auth/SignUp";
import Brand from "../../components/Brand";

import useParticles from "../../hooks/useParticles";

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};
  opacity: 80%;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

const FloatingBrand = styled(Brand)`
  opacity: 80%;
`;

const SignUp: React.FC<unknown> = () => {
  const particles = useParticles();
  return (
    <React.Fragment>
      {particles}
      <FloatingBrand />
      <Wrapper>
        <Helmet title="Sign Up" />

        <Typography
          sx={{ fontSize: "30px", fontWeight: 400 }}
          component="h1"
          variant="h4"
          align="left"
          gutterBottom
        >
          Get started
        </Typography>
        <Typography
          component="h2"
          variant="body1"
          align="left"
          sx={{ color: "#FFFFFFB2" }}
        >
          Start creating the best possible user experience for your customers
        </Typography>

        <SignUpComponent />
      </Wrapper>
    </React.Fragment>
  );
};

export default SignUp;
