import React from 'react';

type FormErrorProps = {
  message: string;
}

const FormError: React.FC<FormErrorProps> = (props) => {
  const { message } = props;

  return (
    <div className="auth-wrapper__form-error">
      <span>
        Unexpected error when signing up: {message}
        <br /><br />
        If you previously signed in using Google authentication, please retry signing in using Google authentication
        <br /><br />
        Please try signing up again. If this persists, then <a href="mailto:support@compose.ai?subject=Issue%20Signing%20Up">please contact our team</a>
      </span>
    </div>
  );
}

export default FormError;
