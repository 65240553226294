import * as React from "react";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";

import { Tooltip, Menu, MenuItem, Button } from "@mui/material";
import { useTrack } from "../../hooks/useSegment";

const Flag = styled.img`
  height: 10px;
  width: 18px;
  left: 0px;
  top: 0.9px;
  margin-right: 8px;
`;
const StyledButton = styled(Button)`
  padding: 4px 10px;
`;
type languageOptionsType = {
  [key: string]: {
    icon: string;
    name: string;
  };
};

const languageOptions: languageOptionsType = {
  en: {
    icon: "/static/img/flags/us.png",
    name: "ENG",
  },
  fr: {
    icon: "/static/img/flags/fr.png",
    name: "FRE",
  },
  de: {
    icon: "/static/img/flags/de.png",
    name: "GER",
  },
  nl: {
    icon: "/static/img/flags/nl.png",
    name: "DUT",
  },
};

function NavbarLanguagesDropdown() {
  const { i18n } = useTranslation();
  const { track } = useTrack();
  const [anchorMenu, setAnchorMenu] = React.useState<any>(null);

  const selectedLanguage = languageOptions[i18n.language];

  const toggleMenu = (event: React.SyntheticEvent) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const handleLanguageChange = React.useCallback(
    (language: string) => {
      // i18n.changeLanguage(language);
      track("Request Language", {});
      window.open("//feedback.compose.ai");
      closeMenu();
    },
    [track]
  );

  return (
    <React.Fragment>
      <Tooltip title="Languages">
        <StyledButton
          aria-owns={anchorMenu ? "menu-appbar" : undefined}
          aria-haspopup="true"
          onClick={toggleMenu}
          color="inherit"
          size="large"
          variant="outlined"
        >
          <Flag src={selectedLanguage.icon} alt={selectedLanguage.name} />
          {selectedLanguage.name}
        </StyledButton>
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        <MenuItem onClick={() => handleLanguageChange("")}>
          Request a language
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}

export default NavbarLanguagesDropdown;
