import React from 'react';
import { useFormikContext } from 'formik';

import { Spinner } from './Style'

const SignUpForm: React.FC<unknown> = () => {
  const {
    values,
    submitForm,
    handleChange,
    errors,
    touched,
    handleBlur,
  } = useFormikContext<{
    firstName: string,
    lastName: string,
    email: string,
    password: string,
    isLoading: false;
  }>();

  const hasFirstNameError = errors.firstName && touched.firstName === true;
  const hasLastNameError = errors.lastName && touched.lastName === true;
  const hasEmailError = errors.email && touched.email === true;
  const hasPasswordError = errors.password && touched.password === true;

  const firstNameClassNames = hasFirstNameError ? 'auth-wrapper__form-input-error' : 'auth-wrapper__form-input';
  const lastNameClassNames = hasLastNameError ? 'auth-wrapper__form-input-error' : 'auth-wrapper__form-input';
  const emailClassNames = hasEmailError ? 'auth-wrapper__form-input-error' : 'auth-wrapper__form-input';
  const passwordClassNames = hasPasswordError ? 'auth-wrapper__form-input-error' : 'auth-wrapper__form-input';

  return (
    <>
      <input
        className={firstNameClassNames}
        name="firstName"
        placeholder="First name"
        value={values.firstName}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      {hasFirstNameError && (
        <div className='auth-wrapper__form-error'>
          {errors.firstName}
        </div>
      )}
      <input
        className={lastNameClassNames}
        name="lastName"
        placeholder="Last name"
        value={values.lastName}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      {hasLastNameError && (
        <div className='auth-wrapper__form-error'>
          {errors.lastName}
        </div>
      )}
      <input
        className={emailClassNames}
        maxLength={256}
        name="email"
        placeholder="Email address"
        value={values.email}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      {hasEmailError && (
        <div className='auth-wrapper__form-error'>
          {errors.email}
        </div>
      )}
      <input
        className={passwordClassNames}
        type="password"
        maxLength={256}
        name="password"
        placeholder="Password"
        value={values.password}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      {hasPasswordError && (
        <div className='auth-wrapper__form-error'>
          {errors.password}
        </div>
      )}
      <button className="auth-wrapper__submit" type='submit' onClick={submitForm}>
        {values.isLoading && <Spinner />}
        {!values.isLoading && "Sign up"}
      </button>
    </>
  );
}

export default SignUpForm;
