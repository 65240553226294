import { apiConfig } from "../config";
import axios from "axios";
import { Tool } from "../pages/tools/toolsList";
import { GENERATE_CALL_TYPES } from "../constants";
import { TrackMetric } from "../hooks/useSegment";

const toolsWithLessResults = [
  "attentioninterest_desireaction",
  "painagitate_solution",
  "quest_copywriting",
  "problempromise_proofproposal",
  "beforeafter_bridge",
  "bullet_point_blog_section",
  "feature_advantage_benefits",
  "listicle",
];

export type OpenAIPromptResult = string;

export async function generateToolResults(
  tool: Tool,
  text: string,
  count: number,
  track: TrackMetric,
  similarText = "",
  generateCallType: string,
  name?: string
): Promise<OpenAIPromptResult[]> {
  if (!text) {
    return [];
  }

  console.log(apiConfig.url);
  const startTime = new Date();
  track("Generate Tool Results", {
    clickGenerateCount: count,
    toolName: tool.name,
  });

  const promises = [];
  const doesNeedMoreAPICalls =
    toolsWithLessResults.findIndex((toolType) => toolType === tool.apiType) !==
    -1;
  const numberOfAPICalls = doesNeedMoreAPICalls ? 5 : 1;
  const skipCache =
    generateCallType !== GENERATE_CALL_TYPES.generate || doesNeedMoreAPICalls;
  for (let apiCount = 0; apiCount < numberOfAPICalls; apiCount++) {
    promises.push(
      axios.post(apiConfig.url + tool.apiType, {
        type: tool.apiType,
        similar_text: similarText,
        text,
        skip_cache: skipCache,
        name,
      })
    );
  }

  const res = await Promise.all(promises);
  const data = res.map((res) => res.data)?.flat();
  const endTime = new Date();
  const latencyInSeconds = (+endTime - +startTime) / 1000;
  const filteredData = data.filter((result: string) => !!result);
  track("API Response Received", {
    resultsCount: filteredData.length,
    toolName: tool.name,
    latencyInSeconds,
  });

  return filteredData;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fetchApiErrorMsg = (e: any): string =>
  e?.message
    ? `API Error - ${e.message} - If this continues contact support using the icon on bottom right of screen.`
    : "Something went wrong";
