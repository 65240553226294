import styled, { withTheme } from "styled-components/macro";
import { LogIn } from "react-feather";
import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";

import {
  Grid,
  Hidden,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar,
  Tooltip,
} from "@mui/material";

import { Menu as MenuIcon } from "@mui/icons-material";

import NavbarLanguagesDropdown from "./NavbarLanguagesDropdown";
import NavbarUserDropdown from "./NavbarUserDropdown";
import { DefaultTheme } from "@mui/styles";
import React from "react";
import { NavbarSubscriptionStatus } from "./NavbarSubscriptionStatus";

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;
const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

type NavbarProps = {
  onDrawerToggle: React.MouseEventHandler<HTMLElement>;
  theme?: DefaultTheme;
};

const Navbar: React.FC<NavbarProps> = ({ onDrawerToggle, theme }) => {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <AppBar position="sticky" elevation={0} theme={theme}>
        <Toolbar>
          <Grid container alignItems="center">
            <Hidden mdUp>
              <Grid item>
                <IconButton
                  theme={theme}
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={onDrawerToggle}
                  size="large"
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>
            {/* Uncomment to add back Search */}
            {/*<Grid item>*/}
            {/*  <Search>*/}
            {/*    <SearchIconWrapper>*/}
            {/*      <SearchIcon />*/}
            {/*    </SearchIconWrapper>*/}
            {/*    <Input placeholder={t("Search")} />*/}
            {/*  </Search>*/}
            {/*</Grid>*/}
            <Grid item xs />
            <Grid item>
              {/* Uncomment to add back messages */}
              {/*<NavbarMessagesDropdown />*/}
              {/*<NavbarNotificationsDropdown />*/}

              <NavbarSubscriptionStatus />

              <NavbarLanguagesDropdown />
              {isAuthenticated ? (
                <NavbarUserDropdown />
              ) : (
                <Tooltip title="Login">
                  <IconButton
                    color="inherit"
                    size="large"
                    onClick={() => navigate("/auth/sign-in")}
                  >
                    <LogIn />
                  </IconButton>
                </Tooltip>
              )}
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default withTheme(Navbar);
