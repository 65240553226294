import { useRef, useEffect, useReducer } from "react";
import { AuthState } from "../types/auth";

export default function usePersistedReducer<State, Action>(
  reducer: (state: State, action: Action) => State,
  initialState: State,
  storageKey: string
) {
  const [state, dispatch] = useReducer(reducer, initialState, init);
  const prevState = usePrevious(state);

  function init() {
    const stringState = localStorage.getItem(storageKey);
    if (stringState) {
      try {
        const parsedState = JSON.parse(stringState);
        return parsedState;
      } catch (error) {
        return initialState;
      }
    } else {
      return initialState;
    }
  }

  useEffect(() => {
    const stateEqual = Object.is(prevState, state);
    if (!stateEqual) {
      const { user } = state as AuthState & State;
      if (user) {
        const stringifiedState = JSON.stringify({
          ...state,
          user: {
            id: user.uid,
            email: user.email,
            avatar: user.avatar,
            displayName: user.displayName,
            firstName: user.firstName,
            lastName: user.lastName,
            role: "user",
          },
          savedResultsCount: 0, // We don't need to persist the value of savedResultsCount so resetting it to 0
        });
        localStorage.setItem(storageKey, stringifiedState);
      }
    }
  }, [state, prevState, storageKey]);

  return { state, dispatch };
}

// Given any value this hook will return the previous value whenever the current value changes
function usePrevious(value: any) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
