import React from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";

import { Paper, Typography } from "@mui/material";

import Brand from "../../components/Brand";
import SignInComponent from "../../components/auth/SignIn";
import useParticles from "../../hooks/useParticles";

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};
  opacity: 80%;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

const FloatingBrand = styled(Brand)`
  opacity: 80%;
`;

const SignIn: React.FC<unknown> = () => {
  const particles = useParticles();
  return (
    <React.Fragment>
      {particles}
      <FloatingBrand />
      <Wrapper>
        <Helmet title="Sign In" />
        <Typography
          component="h1"
          variant="h4"
          align="left"
          sx={{ marginBottom: 0, fontSize: "30px", fontWeight: 400 }}
        >
          Sign in
        </Typography>

        <SignInComponent />
      </Wrapper>
    </React.Fragment>
  );
};

export default SignIn;
