import { useCallback } from "react";
import { isProd } from "../config";
import { getUserTypes } from "../constants";
import useAuth from "../hooks/useAuth";
import { TrialStatus, TrialType } from "../types/user";

export type TrackMetric = (
  event: string,
  props: Record<string, unknown>
) => void;

export type IdentityMetric = (traits: Record<string, unknown>) => void;

export const useTrack = (): { track: TrackMetric } => {
  const { user, hasYearlySubscription } = useAuth();
  const track = useCallback(
    (event: string, props: Record<string, unknown>) => {
      if (user?.uid || user?.id) {
        window?.analytics?.track({
          userId: user?.uid || user?.id,
          event,
          properties: {
            ...props,
            source: "compose-web",
            email: user?.email,
            ...getUserTypes(user, hasYearlySubscription as boolean),
            name:
              user.displayName ||
              (user.firstName &&
                user.lastName &&
                `${user.firstName} ${user.lastName}`),
          },
        });
      } else {
        props?.email &&
          window?.analytics?.track({
            event,
            properties: {
              ...props,
              source: "compose-web",
            },
          });
      }
    },
    [user, hasYearlySubscription]
  );
  return { track };
};

export interface IdentifyProps {
  /**
   * this is the current user's name
   */
  name: string;

  /**
   * this is the medium through which the current user signed up / signed in
   */
  medium?: string;

  /**
   * this is the status of trial subscription for current user
   */
  trialStatus?: TrialStatus;

  /**
   * this is the type of trial subscription (w/ and w/o payment) for current user
   */
  trialType?: TrialType;
}

//** Send new user info to segment */
export function identify(
  /**
   * this is the current user's id
   */
  id: string,

  /**
   * this is the current user's email id
   */
  email: string,

  props: IdentifyProps
): void {
  window?.analytics?.identify(id, {
    source: segmentSourceName,
    email,
    ...props,
  });
}

const segmentSourceName = `compose-web${isProd ? "" : "-dev"}`;

//** Send event to segment */
export function track(event: string, props: Record<string, unknown>): void {
  window?.analytics?.track({
    event,
    properties: {
      ...props,
      source: segmentSourceName,
    },
  });
}
