import React from 'react';
import { useFormikContext } from 'formik';

import { Spinner } from './Style'

const SignInForm: React.FC<unknown> = () => {
  const {
    values,
    submitForm,
    handleChange,
    errors,
    touched,
    handleBlur,
  } = useFormikContext<{
    email: string,
    password: string;
    isLoading: false;
  }>();

  const hasEmailError = errors.email && touched.email === true;
  const hasPasswordError = errors.password && touched.password === true;

  const emailClassNames = hasEmailError ? 'auth-wrapper__form-input-error' : 'auth-wrapper__form-input';
  const passwordClassNames = hasPasswordError ? 'auth-wrapper__form-input-error' : 'auth-wrapper__form-input';

  return (
    <>
      <input
        className={emailClassNames}
        type="email"
        maxLength={256}
        name="email"
        placeholder="Email address"
        value={values.email}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      {hasEmailError && (
        <div className='auth-wrapper__form-error'>
          {errors.email}
        </div>
      )}
      <input
        className={passwordClassNames}
        type="password"
        maxLength={256}
        name="password"
        placeholder="Password"
        value={values.password}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      {hasPasswordError && (
        <div className='auth-wrapper__form-error'>
          {errors.password}
        </div>
      )}
      <button className="auth-wrapper__submit" type='submit' onClick={submitForm}>
        {values.isLoading && <Spinner />}
        {!values.isLoading && "Sign in"}
      </button>
    </>
  );
}

export default SignInForm;
