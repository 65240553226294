import React from "react";

import async from "./components/Async";

// All pages that rely on 3rd party components (other than Material-UI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size
// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";

// Guards
import AuthGuard from "./components/guards/AuthGuard";

// Auth components
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";
import ResetPassword from "./pages/auth/ResetPassword";
import Page404 from "./pages/auth/Page404";
import Page500 from "./pages/auth/Page500";
import ChromeExtForgotPassword from "./pages/auth/chromeExtension/ForgotPassword";
import ChromeExtResetPassword from "./pages/auth/chromeExtension/ResetPassword";
import ChromeExtSignIn from "./pages/auth/chromeExtension/SignIn";
import ChromeExtSignUp from "./pages/auth/chromeExtension/SignUp";
// Components

// Form components

// Icon components

// Page components
import Pricing from "./pages/pages/Pricing";
import SavedLists from "./pages/pages/Lists";
import SavedListsSettings from "./pages/pages/ListsSettings";
import NewProject from "./pages/pages/NewProject";

// Table components

// Documentation

// Landing

// Protected routes
import { ToolComponent } from "./pages/tools/ToolComponent";
import { tools } from "./pages/tools/toolsList";

const Profile = async(() => import("./pages/pages/Profile"));
const routes = [
  {
    path: "/",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: (
          <AuthGuard>
            <SavedLists />
          </AuthGuard>
        ),
      },
    ],
  },
  {
    path: "pages",
    element: <DashboardLayout />,
    children: [
      {
        path: "profile",
        element: (
          <AuthGuard>
            <Profile />
          </AuthGuard>
        ),
      },
      {
        path: "pricing",
        element: (
          <AuthGuard>
            <Pricing />
          </AuthGuard>
        ),
      },
      ...tools.map((tool) => ({
        path: `${tool.url}`,
        element: (
          <AuthGuard>
            <ToolComponent tool={tool} key={tool.name} />
          </AuthGuard>
        ),
      })),
      {
        path: "savedlists",
        children: [
          {
            path: "",
            element: (
              <AuthGuard>
                <SavedLists />
              </AuthGuard>
            ),
          },
          {
            path: "edit",
            element: (
              <AuthGuard>
                <SavedListsSettings />
              </AuthGuard>
            ),
          },
          {
            path: "new",
            children: [
              {
                path: "",
                element: (
                  <AuthGuard>
                    <NewProject />
                  </AuthGuard>
                ),
              },
              ...tools.map((tool) => ({
                path: `${tool.url}`,
                element: (
                  <AuthGuard>
                    <NewProject tool={tool} />
                  </AuthGuard>
                ),
              })),
            ],
          },
          ...tools.map((tool) => ({
            path: `${tool.url}`,
            element: (
              <AuthGuard>
                <ToolComponent tool={tool} key={tool.name} />
              </AuthGuard>
            ),
          })),
        ],
      },
    ],
  },
  {
    path: "savedlists",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: (
          <AuthGuard>
            <SavedLists />
          </AuthGuard>
        ),
      },
      {
        path: "edit",
        element: (
          <AuthGuard>
            <SavedListsSettings />
          </AuthGuard>
        ),
      },
      {
        path: "new",
        children: [
          {
            path: "",
            element: (
              <AuthGuard>
                <NewProject />
              </AuthGuard>
            ),
          },
          ...tools.map((tool) => ({
            path: `${tool.url}`,
            element: (
              <AuthGuard>
                <NewProject tool={tool} />
              </AuthGuard>
            ),
          })),
        ],
      },
      {
        path: ":id",
        children: [
          ...tools.map((tool) => ({
            path: `${tool.url}`,
            element: (
              <AuthGuard>
                <ToolComponent tool={tool} key={tool.name} />
              </AuthGuard>
            ),
          })),
        ],
      },
      ...tools.map((tool) => ({
        path: `${tool.url}`,
        element: (
          <AuthGuard>
            <ToolComponent tool={tool} key={tool.name} />
          </AuthGuard>
        ),
      })),
    ],
  },
  {
    path: "auth",
    element: <AuthLayout />,
    children: [
      {
        path: "sign-in",
        element: <SignIn />,
      },
      {
        path: "sign-up",
        element: <SignUp />,
      },
      {
        path: "reset-password",
        element: <ResetPassword />,
      },
      {
        path: "404",
        element: <Page404 />,
      },
      {
        path: "500",
        element: <Page500 />,
      },
    ],
  },
  {
    path: "chrome-extension/forgot-password",
    element: <ChromeExtForgotPassword />,
  },
  {
    path: "chrome-extension/reset-password",
    element: <ChromeExtResetPassword />,
  },
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

export const ROUTE = {
  SIGN_IN: '/sign-in',
  SIGN_UP: '/sign-up',
  RESET_PASSWORD: '/reset-password',
  FORGOT_PASSWORD: '/forgot-password',
} as const;

export const ROUTES_WITHOUT_INTERCOM = [
  ROUTE.SIGN_IN,
  ROUTE.SIGN_UP,
  ROUTE.FORGOT_PASSWORD,
  ROUTE.RESET_PASSWORD,
] as const;

// More info here: https://tracker.geniusee.tk/browse/COM-276
function RedirectToMain() {
  window.location.replace("https://www.compose.ai/");

  return null;
}

const onlyExtensionRoutes = [
  {
    path: ROUTE.FORGOT_PASSWORD,
    element: <ChromeExtForgotPassword />,
  },
  {
    path: ROUTE.RESET_PASSWORD,
    element: <ChromeExtResetPassword />,
  },
  {
    path: ROUTE.SIGN_IN,
    element: <ChromeExtSignIn />,
  },
  {
    path: ROUTE.SIGN_UP,
    element: <ChromeExtSignUp />,
  },
  {
    path: "*",
    element: <RedirectToMain />,
  },
];

export default onlyExtensionRoutes;
