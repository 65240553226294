import React, { useState } from "react";
import styled from "styled-components/macro";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Grid,
  Alert as MuiAlert,
  Card as MuiCard,
  CardContent,
  Button as MuiButton,
  TextField as MuiTextField,
  IconButton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import { spacing } from "@mui/system";
import { fetchApiErrorMsg } from "../../utils/api";
import { onMetaOrCtrlEnter } from "../../utils/common";

import {
  Edit as EditIcon,
  DeleteForever as DeleteForeverIcon,
  CheckCircle as CheckCircleIcon,
} from "@mui/icons-material";
import { ISavedList } from "../../pages/pages/ListsSettings";

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

const Card = styled(MuiCard)(spacing);

const Alert = styled(MuiAlert)(spacing);

const Button = styled(MuiButton)(spacing);

const useStyles = makeStyles(() => ({
  noBorder: {
    border: "none",
  },
  input: {
    padding: 0,
    margin: 0,
    fontSize: "0.85rem",
  },
}));

type SavedListFormValues = {
  /**
   * stores the value of the text field containing current saved list name
   */
  savedList: string;
  /**
   * stores the value of the submit status
   */
  submit: boolean;
};

type SavedListFormActions = {
  /**
   * used for setting error in formik
   */
  setErrors: (errorObj: Record<string, string>) => void;
  /**
   * used for setting status message in formik
   */
  setStatus: (successObj: Record<string, boolean>) => void;
};

interface SavedListsSettingsCardProps {
  /**
   * Contains current saved list data
   */
  savedList: ISavedList;
  /**
   * This action will delete the saved list from firebase and apply local side effects too
   */
  deleteSavedList?: (id: string) => void;
  /**
   * This action will update the saved list in firebase and apply local side effects too
   */
  updateSavedList?: (savedListId: string, savedListName: string) => void;
  /**
   * This action will create a new saved list in firebase and apply local side effects too
   */
  createSavedList?: (savedList: ISavedList) => void;
}

/**
 * SavedListsSettingsCard is the component that displays the created list name with action buttons for manipulating the list details
 * It is used for updating the name of list , or deleting the list from user's data
 */
const SavedListsSettingsCard: React.FC<SavedListsSettingsCardProps> = ({
  savedList,
  deleteSavedList,
  updateSavedList = () => {},
  createSavedList = () => {},
}: SavedListsSettingsCardProps) => {
  const [isEditable, setIsEditable] = useState<boolean>(
    savedList.notCreatedYet
  );

  const classes = useStyles();

  const initialValues: SavedListFormValues = {
    savedList: savedList.name ?? "",
    submit: false,
  };

  const addEditSavedList = async (
    values: SavedListFormValues,
    { setErrors, setStatus }: SavedListFormActions
  ) => {
    try {
      (await savedList.notCreatedYet)
        ? createSavedList({ ...savedList, name: values.savedList })
        : updateSavedList(savedList.id, values.savedList);
      setIsEditable(false);
    } catch (error: any) {
      const message = fetchApiErrorMsg(error);
      setErrors({ submit: message });
      setStatus({ success: false });
    }
  };

  return (
    <Grid item xs={12} lg={12} xl={12}>
      <Card>
        <CardContent>
          <Formik
            initialValues={initialValues}
            validationSchema={Yup.object().shape({
              savedList: Yup.string()
                .max(255)
                .required("Saved List is required"),
            })}
            onSubmit={addEditSavedList}
          >
            {({
              errors,
              handleChange,
              handleSubmit,
              handleBlur,
              touched,
              values,
            }) => (
              <form
                noValidate
                onSubmit={handleSubmit}
                onKeyDown={onMetaOrCtrlEnter(handleSubmit)}
              >
                {errors.submit && (
                  <Alert mt={2} mb={1} severity="warning">
                    {errors.submit}
                  </Alert>
                )}
                <Grid container>
                  <Grid item xs={10} lg={10} xl={10}>
                    <TextField
                      hiddenLabel
                      name="savedList"
                      fullWidth
                      value={values.savedList}
                      disabled={!isEditable}
                      error={Boolean(touched.savedList && errors.savedList)}
                      onBlur={(e) => {
                        setIsEditable(false);
                        handleBlur(e);
                      }}
                      sx={{ color: "#adb1b7 !important" }}
                      onChange={handleChange}
                      autoFocus={isEditable}
                      inputRef={(input) => input && isEditable && input.focus()}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.noBorder,
                          input: classes.input,
                        },
                      }}
                      my={2}
                    />
                  </Grid>
                  <Grid item xs={2} lg={2} xl={2}>
                    {isEditable ? (
                      <Button
                        type="submit"
                        sx={{ color: "#9197a0", minWidth: "fit-content" }}
                      >
                        <CheckCircleIcon sx={{ color: "#9197a0" }} />
                      </Button>
                    ) : (
                      <IconButton onClick={() => setIsEditable(true)}>
                        <EditIcon sx={{ color: "#9197a0" }} />
                      </IconButton>
                    )}
                    {deleteSavedList && (
                      <IconButton onClick={() => deleteSavedList(savedList.id)}>
                        <DeleteForeverIcon sx={{ color: "#9197a0" }} />
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default SavedListsSettingsCard;
