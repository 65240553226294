import { AuthUser } from "./types/auth";

// Themes
export const THEMES = {
  DEFAULT: "DEFAULT",
  DARK: "DARK",
  AUTH: "AUTH",
  LIGHT: "LIGHT",
  BLUE: "BLUE",
  GREEN: "GREEN",
  INDIGO: "INDIGO",
  EXT_AUTH: "EXT_AUTH"
};

export const GENERATE_CALL_TYPES = {
  moreLikeThis: "more-like-this",
  loadMore: "load-more",
  generate: "generate",
};

/**
 * These are the onboarding steps that are used to be stored in user's firestore document - to store user's onboarding progress
 * Steps of onboarding in order of appearance:
 * 1. subscription: This is the 1st step till the point, the user has started a trial subscription.
 * 2. create-list/highlight-tools: Once a default list is created in registration process, user gets to see tools sidebar as highlighted with a tooltip explaining how to use these tools.
 * 3. done: Once user tries to click on the tools or anywhere out, his/her onboarding is set as done.
 */
export type OnboardingSteps =
  | "subscription"
  | "create-list"
  | "highlight-tools"
  | "done"
  | null;

// The values of this constant depicts the maximum allowed credits for making openAI calls per month
export const MAX_OPENAI_CREDITS = 100;

/**
 * These are the possible experiments we have conducted so far, and are used for identifying track messages
 * 1. free-trial: this experiment indicates the users who have been bypassed payment checkout and have trial for 7 days (allow_trial_without_payment = true when user registered)
 * 2. special-users: this experiment indicates the users who have been given premium subscription as per this task - https://linear.app/composeai/issue/COM-1716/grant-yearly-subscription-to-special-accounts-on-compose-web
 */
type Experiments = "free-trial" | "special-users";

interface IExperiments {
  /**
   * This does not possess any value as of now, since this has been added for future configuration.
   * Please note that we may change the type of this from string to required type later.
   */
  configValue: string;

  /**
   * This decides whether the experiment is enabled or not for current user.
   * Please note that for now we do not pass "enabled: false" additionally for the experiments that a user does not belong to,
   * but this flag helps in better filtering out users who fall under a certain experiment.
   */
  enabled: boolean;
}

export type UserType = Record<
  "experiments",
  Partial<Record<Experiments, IExperiments>>
>;

/**
 * This method returns the data we need to pass for tracking whether the current user falls under an experiment or not.
 * returning null from this method would mean that current user is not a part of any of the experiments
 */
export const getUserTypes = (
  /**
   * This is the current user's data from firebase
   */
  user: AuthUser,

  /**
   * This is the flag which decides if the current user is one of the special group users, having premium access without subscription
   */
  hasYearlySubscription: boolean
): UserType | null => {
  if (user?.allowTrial) {
    return {
      experiments: {
        "free-trial": {
          configValue: "",
          enabled: true,
        },
      },
    };
  } else if (hasYearlySubscription) {
    return {
      experiments: {
        "special-users": {
          configValue: "",
          enabled: true,
        },
      },
    };
  } else {
    return null;
  }
};

export const composeDemoLoomEmbedLink =
  "https://www.loom.com/embed/a6a6867299384a99b3b51c43bcc560c9";

// https://userflow.com/app/compose-ai-2/content/78f66291-1269-4977-a68f-449cee82ce55/builder
export const userOnboardingUserflowId = "78f66291-1269-4977-a68f-449cee82ce55";
export const userFlowKey = "ct_nqsqibikbjgrfjywb6s2gc4jhq";

export const SIGN_IN_UP_SUCCESS_REDIRECT = "https://www.compose.ai/success";

export const IS_PROD = process.env.REACT_APP_FIREBASE_PROJECT_ID === 'compose-301318';

export const EXTENSION_ID = 'ddlbpiadoechcolndfeaonajmngmhblj';