import React, { Dispatch, useState } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { NavigateFunction, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";

import {
  Alert as MuiAlert,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  FormControl as MuiFormControl,
  Grid,
  TextField as MuiTextField,
  Typography,
  LinearProgress,
} from "@mui/material";
import { spacing, SpacingProps } from "@mui/system";
import useAuth from "../../hooks/useAuth";
import { Tool } from "../tools/toolsList";
import { AuthUser } from "../../types/auth";
import { useTrack } from "../../hooks/useSegment";
import { fetchApiErrorMsg } from "../../utils/api";
import { onMetaOrCtrlEnter } from "../../utils/common";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const FormControl = styled(MuiFormControl)<{ my?: number }>(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

const Alert = styled(MuiAlert)(spacing);

interface ButtonProps extends SpacingProps {
  component?: string;
}
interface ProjectCardProps {
  toggleSubmit: Dispatch<boolean>;
  navigate: NavigateFunction;
  user: AuthUser;
  tool?: Tool;
}
const Button = styled(MuiButton)<ButtonProps>(spacing);
const Hyperlinked = styled.div`
  text-decoration: underline;
  font-size: 1rem;
  text-align: center;
  width: 100%;
  cursor: pointer;
  margin-top: -5px;
`;
function ProjectCard(props: ProjectCardProps) {
  const { toggleSubmit, user, navigate, tool } = props;
  return (
    <Card mb={6}>
      <CardContent>
        <Grid container spacing={6}>
          <Grid item md={12}>
            <Formik
              initialValues={{
                projectName: "",
                relevantWebsite: "",
                submit: false,
              }}
              validationSchema={Yup.object().shape({
                projectName: Yup.string()
                  .trim()
                  .required("Project Name is required"),
                relevantWebsite: Yup.string()
                  .trim()
                  .required("Relevant Website is required"),
              })}
              onSubmit={async (values, { setErrors, setStatus }) => {
                try {
                  toggleSubmit(true);
                  const docId = await user?.doc.collection("projects").doc().id;
                  await user?.doc.collection("projects").doc(docId).set({
                    name: values.projectName,
                    url: values.relevantWebsite,
                  });
                  const navigationURL = `/savedlists/${docId}${
                    tool && tool.url ? "/" + tool.url : ""
                  }`;
                  navigate(navigationURL);
                } catch (error) {
                  const message = fetchApiErrorMsg(error);

                  setStatus({ success: false });
                  setErrors({ submit: message });
                } finally {
                  // Hide the loader at the end of try/catch block
                  toggleSubmit(false);
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
              }) => (
                <form
                  noValidate
                  onSubmit={handleSubmit}
                  onKeyDown={onMetaOrCtrlEnter(handleSubmit)}
                >
                  {errors.submit && (
                    <Alert mt={2} mb={3} severity="warning">
                      {errors.submit}
                    </Alert>
                  )}

                  <TextField
                    id="projectName"
                    label="Project Name"
                    variant="outlined"
                    value={values.projectName}
                    error={Boolean(touched.projectName && errors.projectName)}
                    helperText={Boolean(
                      touched.projectName && errors.projectName
                    )}
                    placeholder="Name of the project"
                    fullWidth
                    my={2}
                    onBlur={handleBlur}
                    inputProps={{ maxLength: 255 }}
                    onChange={handleChange}
                  />
                  <FormControl fullWidth my={2} variant="outlined">
                    <TextField
                      id="relevantWebsite"
                      label="Relevant Website"
                      variant="outlined"
                      placeholder="http://compose.ai"
                      value={values.relevantWebsite}
                      error={Boolean(
                        touched.relevantWebsite && errors.relevantWebsite
                      )}
                      helperText={Boolean(
                        touched.relevantWebsite && errors.relevantWebsite
                      )}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      inputProps={{ maxLength: 255 }}
                    />
                  </FormControl>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    Save changes
                  </Button>
                </form>
              )}
            </Formik>
          </Grid>
        </Grid>{" "}
      </CardContent>
    </Card>
  );
}
interface NewProjectProps {
  tool?: Tool;
}
const NewProject: React.FC<NewProjectProps> = ({ tool }: NewProjectProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { user } = useAuth();
  const { track } = useTrack();
  const navigate = useNavigate();
  const createEmptyProject = async () => {
    try {
      setIsSubmitting(true);
      const docId = await user?.doc.collection("projects").doc().id;
      await user?.doc
        .collection("projects")
        .doc(docId)
        .set({
          name: `${new Date().toLocaleString()} Untitled`,
          url: "",
        });
      track("Skip Project Creation Step", {});
      const navigationURL = `/savedlists/${docId}${
        tool && tool.url ? "/" + tool.url : ""
      }`;
      navigate(navigationURL);
    } catch (e) {
      console.error("Unexpected submission error: ", e);
    } finally {
      setIsSubmitting(false);
    }
  };
  return (
    <React.Fragment>
      <Helmet title="New Project" />

      <Typography variant="h3" gutterBottom>
        New Project
      </Typography>
      {tool?.name && (
        <Typography variant="h4" gutterBottom display="inline">
          {tool.name}
        </Typography>
      )}
      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={8} sx={{ mx: "auto" }}>
          <ProjectCard
            toggleSubmit={setIsSubmitting}
            user={user}
            navigate={navigate}
            tool={tool}
          />
          <Hyperlinked onClick={createEmptyProject}>Skip this step</Hyperlinked>
        </Grid>
      </Grid>

      {isSubmitting && <LinearProgress />}
    </React.Fragment>
  );
};

export default NewProject;
