import { useState, useEffect } from "react";
import firebase from "firebase/app";
import "firebase/functions";
import { getUserStripeRole } from "../utils/stripe";
import useAuth from "./useAuth";
import { stripeConfig } from "../config";
import { Subscription } from "@stripe/firestore-stripe-payments";

export function usePremiumStatus(): boolean {
  const { user } = useAuth();

  const [premiumStatus, setPremiumStatus] = useState<boolean>(true);

  useEffect(() => {
    if (user) {
      const checkPremiumStatus = async function () {
        const status = await getUserStripeRole();
        setPremiumStatus(status === "premium" || status === "premium-monthly");
      };
      checkPremiumStatus();
    }
  }, [user]);

  return premiumStatus;
}

/**
 * This hook provides canceled subscription data for current authenticated user.
 * If there are multiple subscriptions that are canceled, then this will provide the 1st canceled subscription data out of array.
 * The main purpose of this hook is to know if at least one canceled subscription exists, which would mean the user had a trial plan in past.
 */
export function useCanceledSubscription(): {
  /**
   * returns canceled subscription data if user has a past canceled subscription or null if user doesn't have a past canceled subscription
   */
  canceledSubscription: Subscription | null;

  /**
   * returns true if user has a past canceled subscription or false if user doesn't have a past canceled subscription
   */
  hasCanceledSubscriptionInPast: boolean;
} {
  const { user } = useAuth();
  const [subscription, setSubscription] = useState<Subscription | null>(null);

  useEffect(() => {
    if (user) {
      const checkSubscription = async function () {
        await user?.doc
          ?.collection("subscriptions")
          .where("status", "in", ["canceled"])
          .onSnapshot(async (snapshot: firebase.firestore.QuerySnapshot) => {
            /** In this implementation we just want to know if at least one canceled subscription exists - that would mean user had a trial plan in past. */
            const doc = snapshot.docs[0];
            setSubscription(doc?.data() as Subscription);
          });
      };
      checkSubscription();
    }
  }, [user]);

  return {
    canceledSubscription: subscription,
    hasCanceledSubscriptionInPast: !!subscription,
  };
}

export const createPortalLink = async (): Promise<void> => {
  const functionRef = firebase
    .app()
    .functions("us-central1")
    .httpsCallable(`${stripeConfig.extensionRoute}-createPortalLink`);
  const { data } = await functionRef({ returnUrl: window.location.origin });
  window.location.assign(data.url);
};
