import React from 'react';

import {
  AuthFormLogo,
  AuthFormTitle,
  AuthFormSubTitle,
} from './Style';

type DataDescriptionProps = {
  title: string;
  subTitle: string;
}

const DataDescription: React.FC<DataDescriptionProps> = (props) => {
  const { title, subTitle } = props;

  return (
    <>
      <AuthFormLogo href="/">
        <img
          src="https://uploads-ssl.webflow.com/601176a2e3b45908f0088112/62ed7e9d0a7ec331dde05fa3_Logo-min.webp"
          width="182"
          height="32"
          alt="Compose AI logo"
        />
      </AuthFormLogo>
      <AuthFormTitle>{title}</AuthFormTitle>
      <AuthFormSubTitle>{subTitle}</AuthFormSubTitle>
    </>
  );
}

export default DataDescription;
