import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/analytics";
import "firebase/remote-config";

import { firebaseConfig } from "../config";

let firestore = null;
let analytics = null;
let remoteConfig = null;
let auth: firebase.auth.Auth;

if (!firebase.apps.length) {
  const app = firebase.initializeApp(firebaseConfig);
  firestore = firebase.firestore();
  analytics = firebase.analytics();
  auth = firebase.auth(app);

  // Initialize Remote Config and get a reference to the service
  remoteConfig = firebase.remoteConfig();
  remoteConfig.settings.minimumFetchIntervalMillis = 60 * 60 * 1000;
  remoteConfig.defaultConfig = {
    allow_trial_without_payment: false,
  };
  remoteConfig
    .fetchAndActivate()
    .then()
    .catch((err) => {
      console.log("Error: fetching firebase remote config ==========> ", err);
    });
}

export default firebase;
export { firebase, firestore, analytics, remoteConfig, auth };
