import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components/macro";
import firebase from "firebase/app";
import * as Yup from "yup";
import { Formik } from "formik";

import {
  Alert as MuiAlert,
  Button,
  TextField as MuiTextField,
  LinearProgress,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";

import useAuth from "../../hooks/useAuth";
import { fetchApiErrorMsg } from "../../utils/api";

import { Hyperlinked } from "./Style";
import { useTrack } from "../../hooks/useSegment";
import GoogleSignInButton from "../GoogleSignIn";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

const SignUp: React.FC<unknown> = () => {
  const navigate = useNavigate();
  const { signUp } = useAuth();
  const { track } = useTrack();
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const emailId = searchParams.get("email")?.trim()?.toLowerCase() ?? "";
  useEffect(() => {
    const setUnregisteredUser = async () => {
      const firestore = firebase.firestore();
      const usersRef = firestore.collection("unregistered_users").doc(emailId);

      await usersRef.get().then((docSnapshot) => {
        if (!docSnapshot.exists) {
          usersRef.set({ emailId });
        }
      });
    };
    if (emailId) {
      setUnregisteredUser();
    }
  }, [emailId]);

  return (
    <Formik
      initialValues={{
        firstName: "",
        lastName: "",
        email: emailId ?? "",
        password: "",
        confirmPassword: "",
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        firstName: Yup.string().max(255).required("First name is required"),
        lastName: Yup.string().max(255).required("Last name is required"),
        email: Yup.string()
          .email("Must be a valid email")
          .max(255)
          .required("Email is required"),
        password: Yup.string()
          .min(12, "Must be at least 12 characters")
          .max(255)
          .required("Required"),
        confirmPassword: Yup.string().when("password", {
          is: (val: any) => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref("password")],
            "Both password need to be the same"
          ),
        }),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          track("Auth - Form Signup Btn Clicked", {
            email: values.email,
            action: "click",
            buttonType: "sign-up-button",
          });
          setIsLoading(true);
          await signUp(
            values.email,
            values.password,
            values.firstName,
            values.lastName
          );
        } catch (error: any) {
          const message = fetchApiErrorMsg(error);

          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
          setIsLoading(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert mt={2} mb={1} severity="warning">
              {errors.submit}
            </Alert>
          )}
          <GoogleSignInButton
            isLoading={isLoading}
            text={"Sign up with Google"}
            setIsLoading={setIsLoading}
          />
          <Typography
            component="h2"
            variant="body2"
            align="center"
            sx={{ color: "#FFFFFFB2" }}
          >
            OR
          </Typography>
          <TextField
            type="text"
            name="firstName"
            label="First name"
            value={values.firstName}
            error={Boolean(touched.firstName && errors.firstName)}
            fullWidth
            helperText={touched.firstName && errors.firstName}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />
          <TextField
            type="text"
            name="lastName"
            label="Last name"
            value={values.lastName}
            error={Boolean(touched.lastName && errors.lastName)}
            fullWidth
            helperText={touched.lastName && errors.lastName}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />
          <TextField
            type="email"
            name="email"
            label="Email address"
            value={values.email}
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />
          <TextField
            type="password"
            name="password"
            label="Password"
            value={values.password}
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />
          <TextField
            type="password"
            name="confirmPassword"
            label="Confirm password"
            value={values.confirmPassword}
            error={Boolean(touched.confirmPassword && errors.confirmPassword)}
            fullWidth
            helperText={touched.confirmPassword && errors.confirmPassword}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isLoading}
          >
            Sign up for Free Trial
          </Button>
          <Hyperlinked>
            Have an account?{" "}
            <span
              onClick={() => navigate("/auth/sign-in")}
              style={{ color: "#407AD6" }}
            >
              Login
            </span>
          </Hyperlinked>
          {isLoading && <LinearProgress />}
        </form>
      )}
    </Formik>
  );
};

export default SignUp;
