import React, { useCallback, useState, useEffect, ChangeEvent } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import firebase from "firebase/app";
import { useTrack } from "../../hooks/useSegment";
import { GENERATE_CALL_TYPES, MAX_OPENAI_CREDITS } from "../../constants";
import {
  Alert,
  Box,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  Divider as MuiDivider,
  Grid,
  Snackbar,
  TextField as MuiTextField,
  Typography,
  Autocomplete,
} from "@mui/material";
import { spacing, SpacingProps } from "@mui/system";
import { Edit3 } from "react-feather";
import { generateToolResults, fetchApiErrorMsg } from "../../utils/api";
import { onMetaOrCtrlEnter } from "../../utils/common";
import { Tool, ToolField } from "./toolsList";
import * as R from "ramda";
import useAuth from "../../hooks/useAuth";
import ComposedToolCard from "./ComposedToolCard";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

interface MyButtonProps extends SpacingProps {
  component?: string;
}
const Button = styled(MuiButton)<MyButtonProps>(spacing);
const Hyperlinked = styled.span`
  text-decoration: underline;
  text-align: center;
  width: 100%;
  cursor: pointer;
  margin-top: -5px;
`;

type ToolCardOnChange = (
  event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  idx: number,
  field: ToolField
) => void;

type ISavedList = firebase.firestore.DocumentData | null;

interface ToolCardProps {
  handleSave: () => void;
  tool: Tool;
  savedLists: AutocompleteOption[];
  savedList: ISavedList;
  setSavedList: (savedList: ISavedList) => void;
  onChange: ToolCardOnChange;
  fieldValues: string[];
  fieldErrors: boolean[];
  disabled: boolean;
  savedData: ToolComponentSavedData[];
}

const ToolCard: React.FC<ToolCardProps> = ({
  handleSave,
  savedLists,
  setSavedList,
  savedList,
  tool,
  onChange,
  fieldValues,
  fieldErrors,
  disabled,
  savedData,
}) => {
  return (
    <Card mb={6}>
      <form onKeyDown={onMetaOrCtrlEnter(handleSave)}>
        <CardContent>
          <Grid container spacing={6}>
            <Grid item md={12}>
              <Typography
                variant="h4"
                sx={{ marginTop: "20px", marginBottom: "20px" }}
              >
                Describe the product or content you want to promote
              </Typography>
              {tool.fields.map((field, index) => {
                return (
                  <TextField
                    key={`${tool.url}-${index}`}
                    label={field.label}
                    variant="outlined"
                    placeholder={field.placeholder}
                    helperText={field.helperText}
                    required={field.required}
                    fullWidth
                    my={2}
                    value={fieldValues[index]}
                    error={fieldErrors[index]}
                    onChange={(e) => onChange(e, index, field)}
                    multiline={field.isMultiLine}
                    minRows={field.isMultiLine ? 5 : 1}
                  />
                );
              })}
              {savedData && savedData.length > 0 && (
                <React.Fragment>
                  <Typography
                    variant="h5"
                    sx={{
                      display: "block",
                      marginTop: 2,
                      marginBottom: 2,
                    }}
                  >
                    Save the result copy to:{" "}
                  </Typography>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={savedLists}
                    value={savedList}
                    sx={{ width: 300 }}
                    disableClearable
                    onInputChange={(e, value) => {
                      const getSelectedSavedList = savedLists.find(
                        (option) => option.label === value
                      );
                      if (!getSelectedSavedList) {
                        //  For the scenario where user types a list that doesn't exist, we need to allow it to be saved
                        //  Ref - https://linear.app/composeai/issue/COM-1617#comment-839556e2
                        setSavedList({
                          label: value,
                          savedListId: "",
                        });
                      } else {
                        setSavedList(getSelectedSavedList);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Select a list" />
                    )}
                  />
                </React.Fragment>
              )}
            </Grid>
          </Grid>

          <Button
            variant="contained"
            color="primary"
            mt={5}
            disabled={disabled}
            onClick={handleSave}
            startIcon={<Edit3 />}
          >
            Compose
          </Button>
        </CardContent>
      </form>
    </Card>
  );
};

export interface ToolComponentSavedData {
  text: string;
  similarText?: string;
  similarTextId?: string;
}

interface AutocompleteOption {
  label: string;
  savedListId: string;
}

export interface ToolComponentProps {
  tool: Tool;
}

export const ToolComponent: React.FC<ToolComponentProps> = ({
  tool,
}: ToolComponentProps) => {
  const startCount = {
    loadMore: 0,
    generateToolResults: 0,
    saveToolResult: 0,
    removeToolResult: 0,
    moreLikeThis: 0,
  };
  type Counts = typeof startCount;
  const [counts, setCounts] = useState<Counts>(startCount);

  const { track } = useTrack();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [moreLikeThisLoading, setMoreLikeThisLoading] = useState<string>("");
  const [generateCallType, setGenerateCallType] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [moreLikeThisText, setMoreLikeThisText] = useState<string>("");
  const [moreLikeThisId, setMoreLikeThisId] = useState<string>("");
  const [savedData, setSavedData] = useState<ToolComponentSavedData[]>([]);

  const [fieldValues, setFieldValues] = useState<string[]>(
    R.repeat("", tool.fields.length)
  );
  const [fieldErrors, setFieldErrors] = useState<boolean[]>(
    R.repeat(false, tool.fields.length)
  );
  const [hideResults, setHideResults] = useState<boolean>(false);
  const [showSupport, setShowSupport] = useState<boolean>(false);

  const [savedList, setSavedList] = useState<ISavedList>(null);

  const [savedLists, setSavedLists] = useState<AutocompleteOption[]>([]);
  const {
    user,
    subscription,
    updateProfile,
    hasYearlySubscription,
    addSavedResultsCount,
  } = useAuth();

  useEffect(() => {
    if (!user?.doc || !user?.firstName) {
      return;
    }
    let newSavedLists: AutocompleteOption[] = [];
    user?.doc
      ?.collection("projects")
      .get()
      .then((querySnapshot: firebase.firestore.QuerySnapshot) => {
        for (const doc of querySnapshot.docs) {
          newSavedLists = [
            ...newSavedLists,
            {
              label: doc.data().name,
              savedListId: doc.id,
            } as AutocompleteOption,
          ];
          setSavedLists(newSavedLists);
        }
        !savedList?.label && setSavedList(newSavedLists[0]);
        setIsLoading(false);
      });
  }, [user?.firstName, user?.doc, savedList?.label]);

  useEffect(() => {
    track("Tool Page Visited", {
      toolName: tool.name,
    });
  }, [user, tool.name, track]);

  const saveCopy = useCallback(
    async (text: string) => {
      try {
        setIsLoading(true);
        if (!user || !savedList?.label) {
          return;
        }
        let projId = savedList?.savedListId;
        if (!projId) {
          // create new savedList and then save the result to it
          projId = await user?.doc.collection("projects").doc().id;
          await user?.doc.collection("projects").doc(projId).set({
            name: savedList?.label,
            url: "",
          });
          /** side-effect for adding newly created list to autocomplete dropdown options */
          const newListData = {
            label: savedList?.label,
            savedListId: projId,
          };
          setSavedLists([...savedLists, newListData]);
          setSavedList(newListData);
        }
        await user?.doc
          ?.collection("projects")
          .doc(projId)
          .collection("savedResults")
          .add({
            text,
            toolName: tool.name,
          });
        setSuccessMessage("Saved successfully.");
        const newCount = counts.saveToolResult + 1;
        setCounts((currentCount: Counts) => {
          return {
            ...currentCount,
            saveToolResult: newCount,
          };
        });
        track("Save Tool Result", {
          saveCount: newCount,
          toolName: tool.name,
          textSaved: text,
        });
        /** side-effect for adding 1 to savedResultsCount in local store */
        addSavedResultsCount(1);
      } catch (e) {
        console.log(`Error in saving results: ${e}`);
      } finally {
        setIsLoading(false);
      }
    },
    [
      user,
      savedList?.savedListId,
      savedList?.label,
      savedLists,
      tool.name,
      counts.saveToolResult,
      track,
      addSavedResultsCount,
    ]
  );

  const removeCopy = useCallback(
    (text: string) => {
      const removeIndex = savedData.findIndex(
        (response: ToolComponentSavedData) => response.text === text
      );
      savedData.splice(removeIndex, 1);
      setSavedData([...savedData]);
      const newCount = counts.removeToolResult + 1;
      setCounts((currentCount: Counts) => {
        return {
          ...currentCount,
          removeToolResult: newCount,
        };
      });
      track("Remove Tool Result", {
        removeCount: newCount,
        toolName: tool.name,
        textRemoved: text,
      });
      setSuccessMessage("Copy removed successfully");
    },
    [counts.removeToolResult, tool.name, savedData, track]
  );

  const fetchResults = useCallback(
    async (similarText?: string, similarTextId?: string) => {
      try {
        if (
          subscription ||
          (user &&
            user.allowTrial &&
            user.trialEnd &&
            user.trialEnd.toDate().valueOf() > Date.now()) || // trial is still active
          hasYearlySubscription
        ) {
          const newCount = counts.generateToolResults + 1;
          if (user?.openAICreditsCount >= MAX_OPENAI_CREDITS) {
            track("User openAI credits expired", {
              userID: user?.id || user?.uid,
              openAICreditsCount: user?.openAICreditsCount,
            });
            setGenerateCallType("");
            setShowSupport(true);
            return;
          }
          if (!user?.doc || typeof user.openAICreditsCount != "number") {
            setGenerateCallType("");
            return;
          }
          const data = await generateToolResults(
            tool,
            fieldValues.length === 2 ? fieldValues[1] : fieldValues[0],
            newCount,
            track,
            similarText,
            generateCallType,
            fieldValues.length === 2 ? fieldValues[0] : undefined
          );
          setGenerateCallType("");
          setCounts((currentCount: Counts) => {
            return {
              ...currentCount,
              generateToolResults: newCount,
            };
          });
          if (data && data.length > 0) {
            setSavedData((currentSavedData: ToolComponentSavedData[]) => {
              const newData = [
                ...currentSavedData,
                ...data
                  .filter((result: string) => !!result)
                  .map((result: string) =>
                    similarText && similarTextId
                      ? {
                          text: result,
                          similarText,
                          similarTextId,
                        }
                      : {
                          text: result,
                        }
                  ),
              ];
              return newData;
            });
            // TODO: need to keep this logic in Server side to avoid user from getting infinite credits
            // Reference - https://github.com/compose-ai/compose-web/pull/34/files/af874a0a96c2cbfacd4921000f2a7a78aab6d311#r813433344
            updateProfile({
              uid: user?.uid || user?.id,
              openAICreditsCount: (user?.openAICreditsCount || 0) + 1,
            });
          }
        } else {
          setHideResults(true);
          setGenerateCallType("");
          return;
        }
      } catch (e: any) {
        setErrorMessage(fetchApiErrorMsg(e));
      } finally {
        setMoreLikeThisLoading("");
        setIsLoading(false);
      }
    },
    //eslint-disable-next-line
    [
      tool,
      fieldValues,
      counts.generateToolResults,
      track,
      subscription,
      hasYearlySubscription,
      generateCallType,
    ]
  );

  const validateBeforeSave = useCallback(
    async (similarText = "", id = "") => {
      let isInvalid = fieldErrors.some((error) => error);
      if (!isInvalid) {
        tool.fields.forEach((field, index) => {
          if (field.required && !fieldValues[index].trim()) {
            isInvalid = true;
            setFieldErrors((currentFieldErrors) => {
              const newValues = [...currentFieldErrors];
              newValues[index] = true;
              return newValues;
            });
          }
        });
      }
      if (!isInvalid) {
        similarText ? setMoreLikeThisLoading(id) : setIsLoading(true);
        await fetchResults(similarText, id);
      } else {
        setErrorMessage("Please enter data in required fields.");
        setGenerateCallType("");
      }
    },
    [fieldErrors, fieldValues, tool.fields, fetchResults]
  );

  const handleLoadMore = useCallback(async () => {
    const newCount = counts.loadMore + 1;
    setCounts((currentCount: Counts) => {
      return {
        ...currentCount,
        loadMore: newCount,
      };
    });
    track("Load More Results", {
      clickLoadMoreCount: counts.loadMore,
      toolName: tool.name,
    });
    validateBeforeSave();
  }, [counts.loadMore, validateBeforeSave, track, tool]);

  const moreLikeThis = useCallback(
    async (similarText, id) => {
      const newCount = counts.moreLikeThis + 1;
      setCounts((currentCount: Counts) => {
        return {
          ...currentCount,
          moreLikeThis: newCount,
        };
      });
      track("More Like this", {
        clickMoreLikeThisCount: counts.moreLikeThis,
        toolName: tool.name,
      });
      validateBeforeSave(similarText, id);
    },
    [counts.moreLikeThis, validateBeforeSave, track, tool]
  );
  useEffect(() => {
    switch (generateCallType) {
      case GENERATE_CALL_TYPES.generate:
        validateBeforeSave();
        break;
      case GENERATE_CALL_TYPES.loadMore:
        handleLoadMore();
        break;
      case GENERATE_CALL_TYPES.moreLikeThis:
        moreLikeThis(moreLikeThisText, moreLikeThisId);
        break;

      default:
        break;
    }
    // Please do not remove below line as it can lead to infinite loop if we fix warnings
    // eslint-disable-next-line
  }, [generateCallType, moreLikeThisText, moreLikeThisId]);
  const onChange: ToolCardOnChange = (evt, index, field) => {
    setFieldValues((currentFieldValues) => {
      const newValues = [...currentFieldValues];
      newValues[index] = evt.target.value;
      return newValues;
    });
    field.required &&
      setFieldErrors((currentFieldErrors) => {
        const newValues = [...currentFieldErrors];
        newValues[index] = !evt.target.value.trim();
        return newValues;
      });
  };

  return (
    <React.Fragment>
      <Helmet title={tool.name} />
      <Typography variant="h1" gutterBottom>
        {savedList?.name}
      </Typography>
      <Typography variant="h3" gutterBottom display="inline">
        {tool.name}
      </Typography>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={8} sx={{ mx: "auto" }}>
          <ToolCard
            savedLists={savedLists}
            savedList={savedList}
            handleSave={async () => {
              setSavedData([]);
              setGenerateCallType(GENERATE_CALL_TYPES.generate);
            }}
            setSavedList={setSavedList}
            savedData={savedData}
            disabled={isLoading}
            tool={tool}
            onChange={onChange}
            fieldErrors={fieldErrors}
            fieldValues={fieldValues}
          />
          {hideResults && (
            <Alert>
              Your subscription is expired.{" "}
              <Hyperlinked onClick={() => navigate("/pages/pricing")}>
                Click here
              </Hyperlinked>{" "}
              to upgrade.
            </Alert>
          )}
          {showSupport && (
            <Alert>
              Your credits for this month are exhausted. Please{" "}
              <Hyperlinked onClick={() => window.Intercom("show")}>
                contact support
              </Hyperlinked>{" "}
              if you need more credits.
            </Alert>
          )}
          {savedData &&
            savedData.length > 0 &&
            savedData
              .filter(
                (obj: ToolComponentSavedData) =>
                  !!obj.text && !obj.similarText && !obj.similarTextId
              )
              .map((obj: ToolComponentSavedData, idx: number) => (
                <ComposedToolCard
                  id={`composed-tool-card-${idx}`}
                  key={`composed-tool-card-${idx}`}
                  text={obj.text}
                  saveCopy={saveCopy}
                  removeCopy={removeCopy}
                  setSuccessMessage={setSuccessMessage}
                  isLoading={isLoading}
                  tool={tool.name}
                  moreLikeThis={(text: string, id: string) => {
                    setMoreLikeThisId(id);
                    setMoreLikeThisText(text);
                    setGenerateCallType(GENERATE_CALL_TYPES.moreLikeThis);
                  }}
                  moreLikeThisLoading={moreLikeThisLoading}
                  savedData={savedData}
                />
              ))}
          {isLoading && (
            <Box textAlign="center">
              <CircularProgress />
            </Box>
          )}
          <Snackbar
            open={!!errorMessage}
            autoHideDuration={3000}
            onClose={() => setErrorMessage("")}
          >
            <Alert severity="error" sx={{ width: "100%" }}>
              {errorMessage}
            </Alert>
          </Snackbar>
          <Snackbar
            open={!!successMessage}
            autoHideDuration={3000}
            onClose={() => setSuccessMessage("")}
          >
            <Alert sx={{ width: "100%" }}>{successMessage}</Alert>
          </Snackbar>
          {savedData && savedData.length > 0 && (
            <Box textAlign="center">
              <Button
                variant="contained"
                color="primary"
                mt={5}
                onClick={() =>
                  setGenerateCallType(GENERATE_CALL_TYPES.loadMore)
                }
                disabled={isLoading}
              >
                Load more results!
              </Button>
            </Box>
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
