import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components/macro";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";

import {
  Alert as MuiAlert,
  Checkbox,
  FormControlLabel,
  Button,
  TextField as MuiTextField,
  LinearProgress,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";

import useAuth from "../../hooks/useAuth";
import { Hyperlinked } from "./Style";
import { fetchApiErrorMsg } from "../../utils/api";
import { useTrack } from "../../hooks/useSegment";
import GoogleSignInButton from "../GoogleSignIn";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

function SignIn() {
  const navigate = useNavigate();
  const { signIn } = useAuth();
  const [searchParams] = useSearchParams();
  const { track } = useTrack();
  const redirectURL =
    searchParams.get("redirectURL")?.trim()?.toLowerCase() ?? "";

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email("Must be a valid email")
          .max(255)
          .required("Email is required"),
        password: Yup.string().max(255).required("Password is required"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          track("Auth - Form Login Btn Clicked", {
            email: values.email,
            action: "click",
            buttonType: "log-in-button",
          });
          await signIn(values.email, values.password);
          navigate(redirectURL ? redirectURL : "/");
        } catch (error: any) {
          const message = fetchApiErrorMsg(error);

          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert mt={2} mb={3} severity="warning">
              {errors.submit}
            </Alert>
          )}
          <GoogleSignInButton
            isLoading={isSubmitting}
            text={"Sign in with Google"}
            setIsLoading={setSubmitting}
          />
          <Typography
            component="h2"
            variant="body2"
            align="center"
            sx={{ color: "#FFFFFFB2" }}
          >
            OR
          </Typography>
          <TextField
            type="email"
            name="email"
            label="Email Address"
            value={values.email}
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />
          <TextField
            type="password"
            name="password"
            label="Password"
            value={values.password}
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          <Button
            component={Link}
            to="/auth/reset-password"
            sx={{ float: "right", display: " inline" }}
            color="primary"
          >
            Forgot password
          </Button>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            Sign in
          </Button>

          <Hyperlinked>
            Don&apos;t have an account?{" "}
            <span
              style={{ color: "#407AD6" }}
              onClick={() => navigate("/auth/sign-up")}
            >
              Sign up
            </span>
          </Hyperlinked>
          {isSubmitting && <LinearProgress />}
        </form>
      )}
    </Formik>
  );
}

export default SignIn;
