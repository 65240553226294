import { isPageWithIntercomWidget } from './common'

// For reference: Intercom API docs - https://developers.intercom.com/installing-intercom/docs/intercom-javascript
export const restartIntercom = (): void => {
  window.Intercom("shutdown");
  bootIntercom();
};
export const bootIntercom = (): void => {
  try {
    const withIntercomWidget = isPageWithIntercomWidget();

    if (withIntercomWidget) {
      window.Intercom("boot", {
        app_id: process.env.REACT_APP_INTERCOM_APPID,
        source: "compose-web",
      });
      window.intercomSettings = {
        name: "",
      };
      window.Intercom("update");
    }
  } catch (e: any) {
    console.error("Error: ", e);
  }
};
export const updateIntercom = (settings: Record<string, any>): void => {
  window.intercomSettings = settings;
  window.Intercom("update");
};
