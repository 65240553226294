import React from "react";
import { useFormikContext } from "formik";

import { useTrack } from "../../../hooks/useSegment";
import googleIcon from "../../../vendor/assets/google.png";
import useAuth from "../../../hooks/useAuth";
import { sendLoginMessageToExtension } from "../../../utils/common";

import { AuthFormGoogleButton } from './Style';

type AuthFormGoogleButtonComponentProps = {
  action: 'sign-in' | 'sign-up';
}

const AuthFormGoogleButtonComponent: React.FC<AuthFormGoogleButtonComponentProps> = (props) => {
  const { action } = props;

  const { setFieldValue, setStatus, values } = useFormikContext<{ isLoading: boolean }>();

  const { track } = useTrack();

  const { signInWithGoogle, user } = useAuth();

  const signInWithGoogleHandler = async (event: MouseEvent) => {
    event.preventDefault();

    if (values.isLoading) {
      return;
    }

    try {
      setFieldValue('isLoading', true);

      track(`Auth - Google Btn Clicked`, {
        email: user?.email,
        action: "click",
        buttonType: "google-auth-button",
      });

      const { authCred, isNewUser } = await signInWithGoogle();

      if (isNewUser === false && action === 'sign-up') {
        throw new Error('The current account is already registered.');
      }

      // Send a login message to the chrome extension
      await sendLoginMessageToExtension({ authCred });
    } catch (error) {
      const { message } = error as Error;

      setStatus({ error: message });
    } finally {
      setFieldValue('isLoading', false);
    }
  }

  return (
    <AuthFormGoogleButton
      $disabled={values.isLoading}
      onClick={signInWithGoogleHandler as unknown as VoidFunction}
    >
      <img
        src={googleIcon}
        alt="Google icon"
      />
      <div>Sign in with Google</div>
    </AuthFormGoogleButton>
  );
}

export default AuthFormGoogleButtonComponent;
