import React, { useEffect, useState, useCallback } from "react";
import styled from "styled-components/macro";
import { useTrack } from "../../hooks/useSegment";

import {
  Card as MuiCard,
  CardContent,
  Grid,
  LinearProgress,
} from "@mui/material";
import { spacing } from "@mui/system";
import useAuth from "../../hooks/useAuth";
import firebase from "firebase/app";
import { AuthUser } from "../../types/auth";
import ListCard from "../../components/lists/ListCard";

const Card = styled(MuiCard)(spacing);

export type SavedResult = {
  text: string;
  toolName: string;
  id: string;
  isDeleted: boolean;
};

type SavedListDetailsCardProps = {
  results: SavedResult[];
  savedListId: string;
  user: AuthUser;
  savedListName: string;
  removeSavedListElementById: (resultId: string) => void;
};

/**
 * SavedListDetailsCard is used for displaying the saved result copy details for each of the saved lists
 * @required: results - contains saved result copies per list
 * @required: savedListId - contains the id of saved list
 * @required: user - logged in user data
 * @required: savedListName - contains the name of saved list
 * @required: removeSavedListElementById - callback method to parent component - for removing a specific object based on index from savedLists data
 */
const SavedListDetailsCard: React.FC<SavedListDetailsCardProps> = ({
  results,
  savedListId,
  user,
  savedListName,
  removeSavedListElementById,
}) => {
  const { track } = useTrack();
  const [resultsState, setResultsState] = useState<SavedResult[]>(results);
  const [isLoading, setIsLoading] = useState(false);
  const [counts, setCounts] = useState({
    removeToolResult: 0,
  });

  useEffect(() => {
    setResultsState(results);
  }, [results]);

  const removeCopy = useCallback(
    async (savedResult: SavedResult) => {
      try {
        const { text, toolName } = savedResult;
        /** did not destructure id in above line but instead added below line for better naming */
        const resultId = savedResult.id;
        if (!user || !savedListId) {
          return;
        }
        setIsLoading(true);
        await user?.doc
          .collection("projects")
          .doc(savedListId)
          .collection("savedResults")
          .doc(resultId)
          .get()
          .then((querySnapshot: firebase.firestore.DocumentSnapshot) => {
            if (querySnapshot.exists) {
              querySnapshot.ref.update({
                isDeleted: true,
              });
            }
          });
        setCounts((currentCount) => {
          const newCount = currentCount.removeToolResult + 1;
          track("Remove Tool Result", {
            removeCount: newCount,
            toolName,
            textRemoved: text,
          });
          return {
            ...currentCount,
            removeToolResult: newCount,
          };
        });
        removeSavedListElementById(resultId);
      } catch (e) {
        console.error("Unexpected error encountered during removeCopy: ", e);
      } finally {
        setIsLoading(false);
      }
    },
    [user, savedListId, track, removeSavedListElementById]
  );

  return (
    <>
      {resultsState.map((result: SavedResult, idx: number) => (
        <Card mb={6} key={`compose-card-${idx}-${result.id}`}>
          <CardContent sx={{ padding: "16px !important" }}>
            <ListCard
              text={result.text}
              removeCopy={() => removeCopy(result)}
              savedListName={savedListName}
              tool={result.toolName}
            />
            {result.isDeleted}
          </CardContent>
        </Card>
      ))}
    </>
  );
};

/**
 * SavedListDetailsProps is the interface of props being passed to SavedListDetails.
 * "removeSavedListElementById" is the method for removing a specific object based on index from savedLists data in parent component
 * "savedList" object is passed as a prop which has the following data in it:
 * name - name of the saved list document
 * id - document id of the saved list in firestore
 * url(optional) - saved list used to have url as a parameter in previous implementation so its optional to support new/old data
 * results - contains in array format the data of all docs in savedResults collection under this saved list (projects) document
 */
interface SavedListDetailsProps {
  savedList: {
    name: string;
    id: string;
    url?: string;
    results: SavedResult[];
  };
  removeSavedListElementById: (resultId: string) => void;
}

/**
 * SavedListDetails is used for displaying the saved result copy details for each of the saved lists
 * @required: savedList - contains details of single saved list
 */
const SavedListDetails: React.FC<SavedListDetailsProps> = ({
  savedList,
  removeSavedListElementById,
}) => {
  const { user } = useAuth();
  return (
    <>
      {!savedList ? (
        <LinearProgress />
      ) : (
        <Grid container spacing={6}>
          <Grid item xs={12} sx={{ mx: "auto" }}>
            <SavedListDetailsCard
              results={savedList.results}
              savedListId={savedList.id}
              user={user}
              savedListName={savedList.name}
              removeSavedListElementById={removeSavedListElementById}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default SavedListDetails;
