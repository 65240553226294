import { useState, useCallback, useMemo } from "react";
import styled from "styled-components/macro";
import {
  Card as MuiCard,
  CardContent,
  Grid,
  IconButton,
  Typography,
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
} from "@mui/material";
import {
  ExpandMore as ExpandMoreIcon,
  FavoriteBorder as FavoriteBorderIcon,
  ContentCopy as ContentCopyIcon,
  DeleteForever as DeleteForeverIcon,
  PlaylistAdd as PlaylistAddIcon,
} from "@mui/icons-material";
import { spacing, SpacingProps } from "@mui/system";
import { useTrack } from "../../hooks/useSegment";
import { ToolComponentSavedData } from "./ToolComponent";

const Card = styled(MuiCard)(spacing);
const Accordion = styled(MuiAccordion)`
  border-radius: 6px;
  text-align: left;
  box-shadow: 0 2px 6px 0 rgba(18, 38, 63, 0.05);

  &:before {
    display: none;
  }
`;

const AccordionSummary = styled(MuiAccordionSummary)`
  padding: 0 16px;
  box-shadow: 0;
  min-height: 48px !important;

  .MuiAccordionSummary-content {
    margin: 12px 0 !important;
  }
`;

const GridItem = styled((props) => <Grid item {...props} />)`
  padding: 0px !important;
`;

const AccordionDetails = styled(MuiAccordionDetails)`
  padding-left: 16px;
  padding-right: 16px;
`;
interface ButtonProps extends SpacingProps {
  component?: string;
}

interface ComposedToolCardProps {
  text: string;
  tool: string;
  id: string;
  saveCopy?: (text: string) => Promise<void>;
  removeCopy: (text: string) => void;
  setSuccessMessage: (text: string) => void;
  moreLikeThis?: (text: string, id: string) => void;
  moreLikeThisLoading?: string;
  isLoading?: boolean;
  savedData?: ToolComponentSavedData[];
  marginBottom?: number;
}

const ComposedToolCard: React.FC<ComposedToolCardProps> = ({
  text = "",
  saveCopy,
  id,
  removeCopy,
  moreLikeThis,
  isLoading,
  setSuccessMessage,
  moreLikeThisLoading,
  tool = "",
  savedData = [],
  marginBottom = 6,
}: ComposedToolCardProps) => {
  const { track } = useTrack();
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const [isHover, setIsHover] = useState<boolean>(false);

  const startCounts = {
    copyToolResult: 0,
  };
  type Counts = typeof startCounts;
  const [counts, setCounts] = useState<Counts>(startCounts);
  const savedMoreLikeThisData = useMemo(() => {
    if (!savedData?.length) {
      return [];
    }
    return savedData?.filter((savedObj) => savedObj.similarTextId === id);
    // eslint-disable-next-line
  }, [savedData, savedData?.length, id]);

  const copyText = useCallback(() => {
    navigator.clipboard.writeText(text);
    setIsCopied(true);
    const newCount = counts.copyToolResult + 1;
    setCounts((currentCount: Counts) => {
      return {
        ...currentCount,
        copyToolResult: newCount,
      };
    });
    track("Copy Tool Result", {
      copyCount: newCount,
      toolName: tool,
      textCopied: text,
    });
    setSuccessMessage("Copied to clipboard successfully");
  }, [counts.copyToolResult, tool, text, track, setSuccessMessage]);

  return (
    <Card
      mb={marginBottom}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <CardContent sx={{ padding: "16px" }}>
        <Grid
          container
          spacing={6}
          sx={{
            margin: 0,
            alignItems: "center",
            width: "100%",
          }}
        >
          <GridItem md={1}>
            {saveCopy && isHover && (
              <IconButton
                title="Save to list"
                sx={{ visibility: isHover ? "visible" : "hidden" }}
              >
                <FavoriteBorderIcon onClick={() => saveCopy(text)} />
              </IconButton>
            )}
          </GridItem>
          <GridItem md={1}>
            <IconButton
              title="Copy text"
              sx={{ visibility: isHover ? "visible" : "hidden" }}
            >
              <ContentCopyIcon onClick={copyText} />
            </IconButton>
          </GridItem>
          <GridItem md={8}>
            <Typography style={{ whiteSpace: "pre-line" }}>{text}</Typography>
          </GridItem>
          <GridItem md={1}>
            {moreLikeThis && (
              <IconButton
                disabled={!!moreLikeThisLoading && id === moreLikeThisLoading}
                title="More like this"
                sx={{ visibility: isHover ? "visible" : "hidden" }}
              >
                <PlaylistAddIcon onClick={() => moreLikeThis(text, id)} />
              </IconButton>
            )}
          </GridItem>
          <GridItem md={1}>
            <IconButton
              title="Delete result"
              disabled={
                (!!moreLikeThisLoading && id === moreLikeThisLoading) ||
                isLoading
              }
              sx={{ visibility: isHover ? "visible" : "hidden" }}
            >
              <DeleteForeverIcon onClick={() => removeCopy(text)} />
            </IconButton>
          </GridItem>
        </Grid>
      </CardContent>
      {savedMoreLikeThisData?.length > 0 && (
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="faq1-content"
            id="faq1-header"
          >
            <Typography variant="subtitle1">
              See more like this results
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {savedMoreLikeThisData
              .filter((obj: ToolComponentSavedData) => !!obj.text)
              .map((obj: ToolComponentSavedData, idx: number) => (
                <ComposedToolCard
                  key={`composed-tool-card-${id}-${idx}`}
                  id={`composed-tool-card-${id}-${idx}`}
                  text={obj.text}
                  saveCopy={saveCopy}
                  removeCopy={removeCopy}
                  isLoading={isLoading}
                  tool={tool}
                  marginBottom={0}
                  moreLikeThis={moreLikeThis}
                  setSuccessMessage={setSuccessMessage}
                  moreLikeThisLoading={moreLikeThisLoading}
                  savedData={savedData}
                />
              ))}
          </AccordionDetails>
        </Accordion>
      )}
    </Card>
  );
};

export default ComposedToolCard;
