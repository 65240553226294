import { SIGN_IN_UP_SUCCESS_REDIRECT, EXTENSION_ID } from '../constants';
import { firebase } from "../utils/firebase";
import { ROUTES_WITHOUT_INTERCOM } from '../routes';

/**
 * Checks whether ctrl+enter OR cmd+enter keys were pressed, if yes then calls the function passed in callback param.
 *
 * @param callback - method to be called if condition suffices
 * @returns event handler method
 *
 */
export const onMetaOrCtrlEnter = (callback: () => void) => {
  return (event: React.KeyboardEvent<HTMLFormElement>) => {
    if (event && (event.metaKey || event.ctrlKey) && event.key === "Enter") {
      callback();
    }
  };
};

/**
 * Send a message to the ComposeAI chrome extension.
 */
export const sendLoginMessageToExtension = (
  { email, password, authCred }:
    { email?: string; password?: string; authCred?: firebase.auth.AuthCredential | null }
): Promise<void> => {
  return new Promise((resolve, reject) => {
    let cred = authCred;

    // Get credentials with an email and password.
    if (typeof authCred === 'undefined' && typeof email !== 'undefined' && typeof password !== 'undefined') {
      cred = firebase.auth.EmailAuthProvider.credential(email, password);
    }

    if (!chrome?.runtime?.sendMessage) {
      reject(new Error('chrome.runtime.sendMessage is not existed'));
    }

    if (chrome?.runtime?.sendMessage) {
      chrome.runtime.sendMessage(
        EXTENSION_ID,
        {
          type: 'login',
          payload: {
            authCred: cred?.toJSON(),
          },
        },
        (response) => {
          if (response.type === 'success') {
            window.location.replace(SIGN_IN_UP_SUCCESS_REDIRECT);

            resolve();
          } else {
            reject(new Error('Extension error: ' + response.payload?.error?.message))
          }
        }
      );
    }
  });
}

export const isPageWithIntercomWidget = (): boolean => {
  for (const route of ROUTES_WITHOUT_INTERCOM) {
    // Can show intercom widget.
    if (window.location.pathname.includes(route)) {
      return false;
    }
  }

  return true;
}
