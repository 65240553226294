import styled from "styled-components/macro";
import {
  Box,
  Button as MuiButton,
  IconButton as MuiIconButton,
  FormHelperText as MuiFormHelperText,
  Paper,
  TextField as MuiTextField,
  CircularProgress,
} from "@mui/material";
import { createGlobalStyle } from 'styled-components';
import { Link } from 'react-router-dom';

import Background from "../../../vendor/assets/background.svg";

export const MainRoot = styled.div`
  background-image: url(${Background});
  background-size: cover;
  height: 100%;
  width: 100%;
  position: fixed;
`;

export const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

export const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: ${(props) => props.theme.spacing(15)};
`;

export const ExtAuthContainer = styled(Paper)`
  align-items: center;
  display: flex;
  height: auto;
  flex-direction: column;
  justify-content: center;
  left: calc(50% - 520px/2);
  position: absolute;
  width: 520px;
  background: none;
`;

export const ExtAuthBox = styled(Box)`
  width: 440px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0; 
  ${(props) => props.theme.breakpoints.down("sm")} {
    width: 380px;
  }
  
  span {
    color: rgba(0, 0, 0, 0.6);
    font-size: 20px;
    line-height: 180%;
    margin-top: 12px;
    text-align: center;
    font-family: Lato,sans-serif;
  }
  
  h1 {
    font-size: 36px;
    font-weight: 700;
    color: #000000;
    font-family: Lato,sans-serif;
  }
`;

export const Brand = styled.img`
  width: 182px;
  margin-bottom: 32px;
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

export const FormWrapper = styled.div`
  width: 100%;
  ${(props) => props.theme.breakpoints.down("sm")} {
    width: 90%;
  }
`;

export const Button = styled(MuiButton)`
  background: #4552E6;
  height: 42px;
  font-size: 15px;
  font-weight: 500;
  font-family: 'Circular Std', sans-serif;
  text-transform: uppercase;
`;

export const AdornmentIconButton = styled(MuiIconButton) <{ $isHidden?: boolean }>`
  height: 24px;
  width: 24px;
  margin-right: 8px;
  visibility: ${({ $isHidden }) => $isHidden ? "hidden" : "visible"};
  
  svg {
    height: 18px;
    width: 18px;
  }
`;

export const TextField = styled(MuiTextField)`
  margin-bottom: 16px;
  
  & .MuiInputBase-root {
    padding-right: 0;
  }

  & .MuiOutlinedInput-input {
    height: 42px;
    padding: 0 8px;
    font-size: 14px;
  }

  fieldset {
    margin-top: -12px;
  }
`;

export const FormHelperText = styled(MuiFormHelperText)`
  max-width: 100%;
  width: 440px;
  min-height: 48px;
  padding: 10px 16px;
  color: #691616;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #D32F2F;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 14px;
  margin-top: 0;
  margin-bottom: 16px;
  border-radius: 4px;
`;

export const BackLink = styled.a`
  display: block;
  margin-top: 39px;
  margin-bottom: 10px;
  padding: 9px;
  text-align: center;
  cursor: pointer;
  color: #4552E5;
  font-size: 16px;
  text-decoration: none;
  font-family: 'Circular Std',sans-serif;
`;

export const AuthSection = styled.section`
  position: static;
  display: grid;
  overflow: hidden;
  min-height: 100vh;
  min-width: 100px;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  grid-auto-columns: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  background-image: url("https://uploads-ssl.webflow.com/601176a2e3b45908f0088112/62da6d2795fee4f9a4419d6e_bg-bottom.svg"),url("https://uploads-ssl.webflow.com/601176a2e3b45908f0088112/62da6d192e153a1e1b27b182_bg-top.svg");
  background-position: 100% 100%,0 0;
  background-size: auto,auto;
  background-repeat: no-repeat,no-repeat;
  font-family: 'Circular Std',sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  text-align: center;

  ${(props) => props.theme.breakpoints.down("md")} {
    grid-template-columns: 1fr;
  }
`;

export const AuthSectionColumn = styled.div`
  display: flex;
  height: 100%;
  padding: 0px 5%;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(69,82,229,.2);
`;

export const AuthSectionSecondColumn = styled(AuthSectionColumn)`
  display: flex;
  justify-content: flex-start;
  padding: 60px 5% 100px;
  background-color: #fff;
`;

export const AuthImageWrapper = styled.div`
  position: relative;
`;

export const AuthImage = styled.img`
  position: relative;
  border: 0;
  vertical-align: middle;
  display: inline-block;
  height: auto;
  max-width: 100%;
`;

export const FirstImage = styled(AuthImage)`
  width: 504px;
  height: auto;
  max-width: 95%;
  margin-left: 15%;
`;

export const SecondImage = styled(AuthImage)`
  z-index: 1;
  width: 507px;
  margin-top: -21%;
  margin-left: -15%;
`;

export const GlobalStyle = createGlobalStyle`
  @import url(https://fonts.cdnfonts.com/css/circular-std);

  body, html {
    margin: 0;
    padding: 0;
  }
`;

export const AuthWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 440px;
  margin-right: auto;
  margin-left: auto;
  flex-direction: column;

  .auth-wrapper__form-text {
    margin-top: 16px;
    margin-bottom: 16px;
    color: rgba(0,0,0,.6);
    font-size: 14px;
    line-height: 20px;
  }

  .auth-wrapper__submit {
    display: flex;
    height: 42px;
    width: 100%;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background-color: #4552e5;
    transition: background-color .3s;
    font-family: 'Circular Std',sans-serif;
    color: #fff;
    font-size: 15px;
    line-height: 20px;
    font-weight: 500;
    text-align: center;
    letter-spacing: .46px;
    text-decoration: none;
    text-transform: uppercase;
    cursor: pointer;
    outline: none;
    border: none;
  } 

  .auth-wrapper__submit:hover {
    background-color: rgba(69,82,229,.85);
  }

  .auth-wrapper__nav-links {
    margin-top: 63px;
  }

  input {
    height: 40px;
    margin-bottom: 16px;
    padding: 0 16px;
    border: 1px solid #d6dae0;
    border-radius: 4px;
    font-size: 16px;
    line-height: 1.42857143;
    vertical-align: middle;
    background-color: #fff;
    font-family: 'Circular Std',sans-serif;
  }

  input:hover {
    border-color: #969aa0;
  }

  input::placeholder {
    
    opacity: 0.5;
  }

  .auth-wrapper__links {
    margin-top: 14px;
  }

  .auth-wrapper__blue-link {
    color: #4552e5;
    text-decoration: none;
    cursor: pointer;
  }

  .auth-wrapper__blue-link:hover {
    text-decoration: underline;
  }

  .auth-wrapper__form-input-error {
    border-color: #d32f2f;
  }

  .auth-wrapper__form-error {
    margin-bottom: 16px;
    padding: 16px 16px 16px 50px;
    border-radius: 4px;
    background-color: rgba(211,47,47,.1);
    background-image: url("https://uploads-ssl.webflow.com/601176a2e3b45908f0088112/62da6adea561f2ee66b1facb_error.svg");
    background-position: 18px 15px;
    background-size: 20px;
    background-repeat: no-repeat;
    color: #691616;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
  }
`;

export const AuthFormLogo = styled.a`
  margin-bottom: 34px;
  align-self: center;
`;

export const AuthFormTitle = styled.h1`
  margin-top: 0;
  margin-bottom: 0;
  font-family: Lato,sans-serif;
  font-size: 35px;
  line-height: 140%;
  font-weight: 900;
`;

export const AuthFormSubTitle = styled.h1`
  position: relative;
  margin-top: 0;  
  margin-bottom: 20px;
  font-family: 'Circular Std',sans-serif;
  color: rgba(0,0,0,.6);
  font-size: 20px;
  line-height: 32px;
  font-weight: 400;
`;

export const AuthFormGoogleButton = styled.a<{ $disabled?: boolean }>`
  position: relative;
  display: flex;
  min-height: 40px;
  justify-content: center;
  align-items: center;
  grid-column-gap: 9px;
  grid-row-gap: 9px;
  border: 1px solid #d6dae0;
  border-radius: 4px;
  transition: border-color .3s;
  font-family: 'Circular Std',sans-serif;
  color: #000;
  font-size: 15px;
  line-height: 20px;
  font-weight: 500;
  text-align: left;
  letter-spacing: .46px;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;

  &:hover {
    border-color: #969aa0;
  }

  div {
    letter-spacing: 0.46px;
    line-height: 20px;
    font-size: 15px;
    font-weight: 500;
  }

  img {
    width: 24px;
    height: 24px;
  }

  ${(props) => props.$disabled && `
    pointer-events: none;
    opacity: 0.5;
  `};
`;

export const Spinner = styled(CircularProgress)`
  color: #fff;
  width: 20px!important;
  height: 20px!important;
`;

export const BackNavLink = styled(Link)`
  display: block;
  margin-top: 39px;
  margin-bottom: 10px;
  padding: 9px;
  text-align: center;
  cursor: pointer;
  color: #4552E5;
  font-size: 16px;
  text-decoration: none;
  font-family: 'Circular Std',sans-serif;
`;
