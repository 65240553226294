import { useState, FC, useCallback } from "react";
import styled from "styled-components/macro";
import { Button, Alert as MuiAlert } from "@mui/material";
import { spacing } from "@mui/system";
import Google from "../vendor/assets/google.png";
import useAuth from "../hooks/useAuth";
import { useTrack } from "../hooks/useSegment";
import { fetchApiErrorMsg } from "../utils/api";
import { useNavigate, useSearchParams } from "react-router-dom";

const Alert = styled(MuiAlert)(spacing);

const GoogleIcon = styled.img`
  margin: 8px;
  width: 24px;
  height: 24px;
`;

interface GoogleSignInButtonProps {
  /**
   * The text to be shown alongside google icon for signin/signup
   */
  text: string;

  /**
   * The flag that decides whether the button should be disable or not
   */
  isLoading: boolean;

  /**
   * This method is used for toggling the enable/disable mode of form submit button
   */
  setIsLoading: (isLoading: boolean) => void;
}

/**
 * This component can be used for signing into or signing up as new user into the app using google auth provider
 */
export const GoogleSignInButton: FC<GoogleSignInButtonProps> = ({
  text,
  isLoading,
  setIsLoading,
}: GoogleSignInButtonProps) => {
  const [error, setError] = useState<string>("");
  const { track } = useTrack();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { signInWithGoogle, user } = useAuth();
  const redirectURL =
    searchParams.get("redirectURL")?.trim()?.toLowerCase() ?? "";

  const signIn = useCallback(async () => {
    setIsLoading(true);
    try {
      track(`Auth - Google Btn Clicked`, {
        email: user?.email,
        action: "click",
        buttonType: "google-auth-button",
      });

      const { isNewUser } = await signInWithGoogle();
      if (!isNewUser) {
        navigate(redirectURL ? redirectURL : "/");
      }
    } catch (error) {
      const message = fetchApiErrorMsg(error);
      setError(message);
    }
  }, [
    navigate,
    signInWithGoogle,
    redirectURL,
    track,
    user?.email,
    setIsLoading,
  ]);

  return (
    <>
      {error && (
        <Alert mt={2} mb={3} severity="warning">
          {error}
        </Alert>
      )}
      <Button
        fullWidth
        variant="outlined"
        color="primary"
        sx={{
          border: "1px solid rgba(255, 255, 255, 0.3)",
          borderRadius: "4px",
          width: "100%",
          height: "42px",
          margin: "35px 0px 16px 0px",
          color: "#ffffff",
        }}
        disabled={isLoading}
        onClick={signIn}
      >
        <GoogleIcon src={Google} />
        {text}
      </Button>
    </>
  );
};

export default GoogleSignInButton;
