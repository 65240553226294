import React from "react";
import { Helmet } from "react-helmet-async";
import { useFormik, FormikProvider } from "formik";
import * as Yup from "yup";

import {
  AuthSection,
  AuthSectionColumn,
  AuthSectionSecondColumn,
  GlobalStyle,
  AuthWrapper,
} from "../../../components/auth/chromeExtension/Style";
import LeftBanner from "../../../components/auth/chromeExtension/LeftBanner";
import DataDescription from "../../../components/auth/chromeExtension/DataDescription";
import NavigationLinks from "../../../components/auth/chromeExtension/NavigationLinks";
import SignUpForm from "../../../components/auth/chromeExtension/SignUpForm";
import FormError from "../../../components/auth/chromeExtension/FormError";
import AuthFormGoogleButton from "../../../components/auth/chromeExtension/AuthFormGoogleButton";
import useAuth from "../../../hooks/useAuth";
import { sendLoginMessageToExtension } from "../../../utils/common";

const validationSchema = Yup.object().shape({
  firstName: Yup.string().max(255).required("First name is required"),
  lastName: Yup.string().max(255).required("Last name is required"),
  email: Yup.string()
    .email("Must be a valid email")
    .max(255)
    .required("Email is required"),
  password: Yup.string().max(255).required("Password is required"),
});

const SignUp: React.FC<unknown> = () => {
  const { signUp } = useAuth();

  const formik = useFormik<{
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    isLoading: false;
  }>({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      isLoading: false,
    },
    onSubmit: async (values, formikHelpers) => {
      if (values.isLoading) {
        return;
      }

      if (formik.status?.error) {
        formikHelpers.setStatus(undefined);
      }

      formikHelpers.setFieldValue('isLoading', true);

      try {
        await signUp(
          values.email,
          values.password,
          values.firstName,
          values.lastName,
          false,
        );

        // Send a login message to the chrome extension
        await sendLoginMessageToExtension({
          email: values.email,
          password: values.password
        });
      } catch (error) {
        const { message } = error as Error;

        formikHelpers.setStatus({ error: message });
      } finally {
        formikHelpers.setFieldValue('isLoading', false);
      }
    },
    validationSchema,
  });

  return (
    <FormikProvider value={formik}>
      <Helmet>
        <title>Sign Up</title>
      </Helmet>
      <GlobalStyle />
      <AuthSection>
        <AuthSectionColumn>
          <LeftBanner />
        </AuthSectionColumn>
        <AuthSectionSecondColumn>
          <AuthWrapper className="auth-wrapper">
            <DataDescription
              title="Faster and more inspired writing with AI."
              subTitle="Create an account to get all the features."
            />
            {formik.status?.error && (
              <FormError message={formik.status?.error} />
            )}
            <AuthFormGoogleButton action="sign-up" />
            <div className="auth-wrapper__form-text">or get started with email</div>
            <SignUpForm />
            <NavigationLinks links={['signIn']} />
          </AuthWrapper>
        </AuthSectionSecondColumn>
      </AuthSection>
    </FormikProvider>
  );
};

export default SignUp;
