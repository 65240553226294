import { Briefcase } from "react-feather";
import { SidebarItemsType } from "../../types/sidebar";
import { useToolsSection } from "../../pages/tools/toolsList";

const listsSection = [
  {
    href: "/savedlists",
    icon: Briefcase,
    title: "Saved Lists",
  },
] as SidebarItemsType[];

const NavItems = () => [
  {
    title: "Saved Lists",
    pages: listsSection,
  },
  {
    title: "Tools",
    pages: useToolsSection(),
  },
];

export default NavItems;
