import React from "react";
import { Helmet } from "react-helmet-async";
import { useFormik, FormikProvider } from "formik";
import * as Yup from "yup";

import {
  AuthSection,
  AuthSectionColumn,
  AuthSectionSecondColumn,
  GlobalStyle,
  AuthWrapper,
} from "../../../components/auth/chromeExtension/Style";
import LeftBanner from "../../../components/auth/chromeExtension/LeftBanner";
import DataDescription from "../../../components/auth/chromeExtension/DataDescription";
import NavigationLinks from "../../../components/auth/chromeExtension/NavigationLinks";
import SignInForm from "../../../components/auth/chromeExtension/SignInForm";
import FormError from "../../../components/auth/chromeExtension/FormError";
import AuthFormGoogleButton from "../../../components/auth/chromeExtension/AuthFormGoogleButton";
import { firebase, auth } from "../../../utils/firebase";
import { sendLoginMessageToExtension } from "../../../utils/common";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Must be a valid email")
    .max(255)
    .required("Email is required"),
  password: Yup.string().max(255).required("Password is required"),
});

const SignIn: React.FC<unknown> = () => {
  const formik = useFormik<{
    email: string;
    password: string;
    isLoading: boolean;
  }>({
    initialValues: {
      email: '',
      password: '',
      isLoading: false,
    },
    onSubmit: async (values, formikHelpers) => {
      if (values.isLoading) {
        return;
      }

      if (formik.status?.error) {
        formikHelpers.setStatus(undefined);
      }

      formikHelpers.setFieldValue('isLoading', true);

      try {
        const cred = firebase.auth.EmailAuthProvider.credential(values.email, values.password);
        const result = await auth.signInWithCredential(cred);

        if (!result.user) {
          throw new Error('Failed to sign in. Please try again.');
        }

        // Send a login message to the chrome extension
        await sendLoginMessageToExtension({
          email: values.email,
          password: values.password
        });
      } catch (error) {
        const { message } = error as Error;

        formikHelpers.setStatus({ error: message });
      } finally {
        formikHelpers.setFieldValue('isLoading', false);
      }
    },
    validationSchema,
  });

  return (
    <FormikProvider value={formik}>
      <Helmet>
        <title>Log In</title>
      </Helmet>
      <GlobalStyle />
      <AuthSection>
        <AuthSectionColumn>
          <LeftBanner />
        </AuthSectionColumn>
        <AuthSectionSecondColumn>
          <AuthWrapper className="auth-wrapper">
            <DataDescription
              title="Faster and more inspired writing with AI."
              subTitle="Sign in to get all the features."
            />
            {formik.status?.error && (
              <FormError message={formik.status?.error} />
            )}
            <AuthFormGoogleButton action="sign-in" />
            <div className="auth-wrapper__form-text">or sign in with email</div>
            <SignInForm />
            <NavigationLinks links={['signUp', 'forgotPassword']} />
          </AuthWrapper>
        </AuthSectionSecondColumn>
      </AuthSection>
    </FormikProvider>
  );
};

export default SignIn;
