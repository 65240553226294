import React from 'react';

import {
  AuthImageWrapper,
  FirstImage,
  SecondImage,
} from './Style';

const LeftBanner: React.FC<unknown> = () => {
  return (
    <AuthImageWrapper>
      <FirstImage
        src="https://uploads-ssl.webflow.com/601176a2e3b45908f0088112/62ed7bba5cf598616ecc8119_Rephrase%20shortcuts-min.png"
        width="504"
        height="455"
        alt="Rephrase"
        sizes="(max-width: 479px) 68vw, (max-width: 991px) 54vw, 43vw"
        loading="lazy"
        srcSet="
          https://uploads-ssl.webflow.com/601176a2e3b45908f0088112/62ed7bba5cf598616ecc8119_Rephrase%20shortcuts-min-p-500.webp 500w,
          https://uploads-ssl.webflow.com/601176a2e3b45908f0088112/62ed7bba5cf598616ecc8119_Rephrase%20shortcuts-min-p-800.png 800w,
          https://uploads-ssl.webflow.com/601176a2e3b45908f0088112/62ed7bba5cf598616ecc8119_Rephrase%20shortcuts-min.png 1008w
        "
      />
      <SecondImage
        src="https://uploads-ssl.webflow.com/601176a2e3b45908f0088112/62ed7c51bf75bb876bd9339a_Autocomplete-min.png"
        width="507"
        height="399"
        alt="Autocomplete"
        sizes="(max-width: 479px) 68vw, (max-width: 991px) 54vw, 43vw"
        loading="lazy"
        srcSet="
          https://uploads-ssl.webflow.com/601176a2e3b45908f0088112/62ed7c51bf75bb876bd9339a_Autocomplete-min-p-500.webp 500w,
          https://uploads-ssl.webflow.com/601176a2e3b45908f0088112/62ed7c51bf75bb876bd9339a_Autocomplete-min-p-800.webp 800w,
          https://uploads-ssl.webflow.com/601176a2e3b45908f0088112/62ed7c51bf75bb876bd9339a_Autocomplete-min.png 1014w
        "
      />
    </AuthImageWrapper>
  );
}

export default LeftBanner;
