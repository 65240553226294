import { Stripe, loadStripe } from "@stripe/stripe-js";
import { stripeConfig } from "../config";
import firebase from "firebase/app";

let stripePromise: Stripe | null;

async function initializeStripe(): Promise<Stripe | null> {
  if (!stripePromise) {
    if (stripeConfig.key) {
      stripePromise = await loadStripe(stripeConfig.key);
    }
  }
  return stripePromise;
}

export async function getUserStripeRole(): Promise<string> {
  await firebase.auth().currentUser?.getIdToken(true);
  const decodedToken = await firebase.auth().currentUser?.getIdTokenResult();

  return decodedToken?.claims?.stripeRole ?? "";
}

export default initializeStripe;

export const stripeSuccessUrl =
  window.location.origin +
  "?subscription=success&prodId=" +
  stripeConfig.monthlyProductId;

export const stripeCancelUrl = window.location.origin + "/pages/pricing";
