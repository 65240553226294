import React, { useMemo, useCallback, useState } from "react";
import { firebase } from "../../utils/firebase";
import useAuth from "../../hooks/useAuth";
import { useNavigate, useLocation } from "react-router-dom";

import { Button, Typography } from "@mui/material";
import { useCanceledSubscription } from "../../hooks/useStripeStatus";

/** this describes the current auth user's subscritpion status */
export type UserSubscriptionStatus =
  | "trial-not-started"
  | "trial-active"
  | "trial-expired"
  | "active-subscription"
  | "canceled-subscription";

/** this component renders the user subscription text and button based on his/her subscription status, and if user got free trial without card or not */
export const NavbarSubscriptionStatus: React.FC<unknown> = () => {
  const { subscription, user } = useAuth();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  /** this provides remaining days of trial, for a user with active trial */
  const [remainingTrialDays, setRemainingTrialDays] = useState(0);

  const { hasCanceledSubscriptionInPast } = useCanceledSubscription();

  /** this renders the button with dynamic text for navigating to pricing page */
  const getNavigationButtonToPricingPage = useCallback(
    (text = "Subscribe now") => {
      if (pathname === "/pages/pricing") {
        return null;
      } else {
        return (
          <Button
            sx={{
              fontSize: "13px",
              fontWeight: "500",
              lineHeight: "22px",
              letterSpacing: "0.46px",
              textAlign: "left",
              padding: "4px 10px",
              marginLeft: "16px",
              textTransform: "uppercase",
            }}
            onClick={() => navigate("/pages/pricing")}
            variant="contained"
            color="primary"
          >
            {text}
          </Button>
        );
      }
    },
    [navigate, pathname]
  );

  /** this memoizes the value for user subscription status that can be only of UserSubscriptionStatus type */
  const userSubscriptionStatus: UserSubscriptionStatus | undefined =
    useMemo(() => {
      /** user data is unavailable, so show default i.e. no text/button */
      if (!user?.doc || !user?.firstName) return undefined;

      /** user doesn't have an active/trialing subscription in the present and canceled subscription in past */
      if (
        !subscription &&
        !hasCanceledSubscriptionInPast &&
        !user?.allowTrial
      ) {
        return "trial-not-started";
      }

      /** user has an active subscription */
      if (subscription?.status === "active") {
        return "active-subscription";
      }

      /** user was subscribed in the past but it is canceled now, and (user doesn't have a trial subscription w/ OR w/o card) */
      if (
        hasCanceledSubscriptionInPast &&
        !(user?.trialEnd || subscription?.status === "trialing")
      ) {
        return "canceled-subscription";
      }

      /** user seems to have trial started if execution has reached this point without returning, let's check if trial is active/expired */
      const trialEndTime = (subscription?.trial_end ||
        user?.trialEnd) as unknown as firebase.firestore.Timestamp;

      /**
       * TODO: Need to fix so that we don't need to add following line as fallback
       * Ref: https://github.com/compose-ai/compose-web/pull/37#discussion_r814182245
       */
      if (!trialEndTime || !trialEndTime.toDate) {
        return undefined;
      }

      const diffInSeconds = trialEndTime.toDate().valueOf() - Date.now() || 0;
      const diffInDays = Math.round(diffInSeconds / (24 * 3600 * 1000));

      setRemainingTrialDays(diffInDays);

      if (diffInDays >= 0) {
        return "trial-active";
      } else {
        return "trial-expired";
      }
    }, [
      user?.doc,
      user?.trialEnd,
      subscription,
      hasCanceledSubscriptionInPast,
      user?.allowTrial,
      user?.firstName,
    ]);

  /** this provides the main markup i.e. text and button (if applicable), based on user's subscription status and and if user got free trial without card or not */
  const renderSubscriptionMarkup = useCallback(() => {
    switch (userSubscriptionStatus) {
      case "trial-not-started":
        return getNavigationButtonToPricingPage("Start free trial");

      case "active-subscription":
        return null;

      case "trial-active": {
        if (user?.allowTrial) {
          let trialActiveMarkup = null;
          switch (remainingTrialDays) {
            case 0:
              trialActiveMarkup = "Trial is expiring today";
              break;
            case 1:
              trialActiveMarkup = `${remainingTrialDays} day left in trial `;
              break;
            default:
              trialActiveMarkup = `${remainingTrialDays} days left in trial `;
          }
          return (
            <>
              {trialActiveMarkup}
              {getNavigationButtonToPricingPage("Subscribe now")}
            </>
          );
        } else {
          return null;
        }
      }

      case "trial-expired":
      case "canceled-subscription":
        return getNavigationButtonToPricingPage("Subscribe now");

      default:
        return null;
    }
  }, [
    userSubscriptionStatus,
    remainingTrialDays,
    user?.allowTrial,
    getNavigationButtonToPricingPage,
  ]);

  return (
    <Typography
      sx={{
        fontSize: "12px",
        fontWeight: "500",
        lineHeight: "20px",
        letterSpacing: "0.4px",
        marginRight: "24px",
        textAlign: "left",
      }}
      variant="button"
      display="inline"
    >
      {renderSubscriptionMarkup()}
    </Typography>
  );
};
