import React, { useCallback, useEffect } from "react";
import {
  Box,
  Typography,
  Modal,
  Button,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router";
import { stripeConfig } from "../config";
import useAuth from "../hooks/useAuth";
import {
  OnboardingSteps,
  userFlowKey,
  userOnboardingUserflowId,
} from "../constants";
import { useLocation } from "react-router-dom";
import { useCanceledSubscription } from "../hooks/useStripeStatus";
import { identify, IdentifyProps } from "../hooks/useSegment";
import { User } from "../types/user";
import userflow from "userflow.js";

interface WithDialogProps {
  children: React.ReactNode;
  onBoardingStep?: OnboardingSteps;
}

const WithDialog: React.FC<WithDialogProps> = ({
  children,
  onBoardingStep,
}) => {
  const { updateProfile, user, subscription, hasYearlySubscription } =
    useAuth();
  const navigate = useNavigate();
  const { search, pathname } = useLocation();
  const subscriptionStatus = new URLSearchParams(search).get("subscription");
  const subscriptionId =
    (subscriptionStatus === "success" &&
      new URLSearchParams(search).get("prodId")) ||
    "";

  const { hasCanceledSubscriptionInPast } = useCanceledSubscription();
  const isTrialActiveOrDone =
    subscription || hasCanceledSubscriptionInPast || user?.allowTrial;

  useEffect(() => {
    const { uid, id, firstName, lastName, email, allowTrial } = user as User;
    if (!id || !firstName) {
      return;
    }
    const updateOnboardingStep = async () => {
      await updateProfile({
        uid: uid || id,
        onBoardingStep: "highlight-tools",
      });
    };
    if (subscriptionStatus === "success" && onBoardingStep === "subscription") {
      updateOnboardingStep();
    }
    if (subscriptionId === stripeConfig.monthlyProductId) {
      // user has just subscribed to monthly plan so need to update user profile properties - trialStatus and trialType
      const userData = {
        name: `${firstName} ${lastName}`,
        trialStatus: "trialStarted",
        trialType: allowTrial ? "trialWithoutCard" : "trialWithCard",
      } as IdentifyProps;
      identify(uid || id, email, userData);
    }
  }, [onBoardingStep, subscriptionId, subscriptionStatus, updateProfile, user]);

  const renderSubscriptionStepMarkup = useCallback(() => {
    if (subscriptionStatus === "success" && onBoardingStep === "subscription") {
      return (
        <>
          <Box textAlign="center">
            <CircularProgress />
          </Box>
          <Typography variant="h5" component="h2">
            {
              "Please don't close this tab, we are activating your subscription…"
            }
          </Typography>
        </>
      );
    } else if (!isTrialActiveOrDone) {
      return (
        <>
          <Typography variant="h5" component="h2">
            Your free trial has not started yet!
          </Typography>
          <Typography variant="h6" component="h2">
            Click{" "}
            <Button
              sx={{
                display: "inline",
                fontSize: "inherit",
                verticalAlign: "baseline",
                padding: 0,
                minWidth: 0,
                lineHeight: "inherit",
              }}
              onClick={() => navigate("/pages/pricing")}
            >
              here
            </Button>{" "}
            to start 7 days free trial.
          </Typography>
        </>
      );
    } else {
      return <RequestUserToSubscribe />;
    }
  }, [subscriptionStatus, onBoardingStep, navigate, isTrialActiveOrDone]);

  if (pathname === "/pages/pricing") {
    return <React.Fragment>{children}</React.Fragment>;
  }

  switch (onBoardingStep) {
    case "subscription":
      return (
        <>
          <Modal
            open={true}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 400,
                bgcolor: "background.paper",
                border: "2px solid #000",
                boxShadow: 24,
                p: 4,
              }}
            >
              {renderSubscriptionStepMarkup()}
            </Box>
          </Modal>

          {children}
        </>
      );

    // TODO: As some of the existing users still have create-list in their onboarding step, I haven't removed it completely
    // Please take a note that the case: "create-list" shall be removed in future
    case "highlight-tools":
    case "create-list":
      if (user) {
        userflow.init(userFlowKey);
        userflow
          .identify(user.uid || user.id, {
            name: user.displayName,
            email: user.email,
          })
          .then(() => {
            userflow.start(userOnboardingUserflowId);
            updateProfile({
              uid: user.uid || user.id,
              onBoardingStep: "done",
            });
          });
      }
      return null;
    case "done": {
      // hasYearlySubscription is the flag set as true for those specific user group to whom we give free access for a period.
      if (subscription || hasYearlySubscription) {
        return <>{children}</>;
      } else {
        return (
          <>
            <Modal
              open={true}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: 400,
                  bgcolor: "background.paper",
                  border: "2px solid #000",
                  boxShadow: 24,
                  p: 4,
                }}
              >
                <RequestUserToSubscribe />
              </Box>
            </Modal>
            <>{children}</>;
          </>
        );
      }
    }
    default:
      return <>{children}</>;
  }
};

export const RequestUserToSubscribe = (): JSX.Element => {
  const navigate = useNavigate();
  return (
    <>
      <Typography variant="h5" component="h2">
        You need a subscription for accessing the app!
      </Typography>
      <Typography variant="h6" component="h2">
        Please click{" "}
        <Button
          sx={{
            display: "inline",
            fontSize: "inherit",
            verticalAlign: "baseline",
            padding: 0,
            minWidth: 0,
            lineHeight: "inherit",
          }}
          onClick={() => navigate("/pages/pricing")}
        >
          here
        </Button>{" "}
        to proceed
      </Typography>
    </>
  );
};

export default WithDialog;
