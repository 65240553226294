import useTheme from "./useTheme";
import React, { useCallback, useEffect } from "react";
import { THEMES } from "../constants";
import { Engine, ISourceOptions } from "tsparticles-engine";
import { loadFull } from "tsparticles";
import Particles from "react-tsparticles";
import particlesOptions from "../particles.json";

export default function useParticles() {
  const particlesInit = useCallback(async (engine: Engine) => {
    await loadFull(engine);
  }, []);

  const { setTheme } = useTheme();
  useEffect(() => {
    setTheme(THEMES.AUTH);
    return () => {
      setTheme(THEMES.DARK);
    };
  }, [setTheme]);

  return (
    <Particles
      options={particlesOptions as ISourceOptions}
      init={particlesInit}
    />
  );
}
