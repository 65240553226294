import { useState, useCallback } from "react";
import styled from "styled-components/macro";
import {
  Card as MuiCard,
  CardContent,
  Grid,
  Typography,
  Alert,
  Snackbar,
} from "@mui/material";

import { spacing } from "@mui/system";
import {
  ContentCopyOutlined as ContentCopyOutlinedIcon,
  DeleteForever as DeleteForeverIcon,
} from "@mui/icons-material";
import { useTrack } from "../../hooks/useSegment";
import * as React from "react";
import IconButton from "@mui/material/IconButton";

const Card = styled(MuiCard)(spacing);

interface SavedListCardProps {
  /**
   * text content of the result copy
   */
  text: string;

  /**
   * name of the tool for which the result copy is saved
   */
  tool: string;

  /**
   * name of the list the copy was saved to
   */
  savedListName: string;

  /**
   * callback method that removes the copy from selected list
   */
  removeCopy: () => void;
}

/**
 * SavedListCard is the card component of Saved Lists page
 */
const SavedListCard: React.FC<SavedListCardProps> = ({
  text,
  removeCopy,
  tool,
  savedListName,
}: SavedListCardProps) => {
  const { track } = useTrack();
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const [isHover, setIsHover] = useState<boolean>(false);
  /** TODO: Use isCopied for snackbar message in other task */
  const startCounts = {
    copyToolResult: 0,
  };
  type Counts = typeof startCounts;
  const [counts, setCounts] = useState<Counts>(startCounts);
  const [successMessage, setSuccessMessage] = useState<string>("");

  const copyText = useCallback(() => {
    navigator.clipboard.writeText(text);
    setIsCopied(true);
    const newCount = counts.copyToolResult + 1;
    setCounts((currentCount: Counts) => {
      return {
        ...currentCount,
        copyToolResult: newCount,
      };
    });
    track("Copy Tool Result", {
      copyCount: newCount,
      toolName: tool,
      textCopied: text,
    });
    setSuccessMessage("Copied to clipboard successfully");
  }, [counts.copyToolResult, tool, text, track]);

  const removeCopyFromCard = useCallback(() => {
    setSuccessMessage("Removed copy successfully");
    removeCopy();
  }, [removeCopy, setSuccessMessage]);

  return (
    <Card
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <CardContent sx={{ padding: "0px !important" }}>
        <Grid container spacing={6} sx={{ alignItems: "center" }}>
          <Grid item xs={1}>
            <IconButton
              title="Copy to clipboard"
              aria-haspopup="true"
              onClick={copyText}
            >
              <ContentCopyOutlinedIcon
                sx={{ visibility: isHover ? "visible" : "hidden" }}
              />
            </IconButton>
          </Grid>
          <Grid item xs={10}>
            <Typography variant="subtitle2">
              {savedListName} . {tool}
            </Typography>
            <Typography style={{ whiteSpace: "pre-line" }}>{text}</Typography>
          </Grid>
          <Grid item xs={1}>
            <IconButton
              title="Delete"
              aria-haspopup="true"
              onClick={() => removeCopyFromCard()}
            >
              <DeleteForeverIcon
                sx={{ visibility: isHover ? "visible" : "hidden" }}
              />
            </IconButton>
          </Grid>
        </Grid>
      </CardContent>
      <Snackbar
        open={!!successMessage}
        autoHideDuration={3000}
        onClose={() => setSuccessMessage("")}
      >
        <Alert sx={{ width: "100%" }}>{successMessage}</Alert>
      </Snackbar>
    </Card>
  );
};

export default SavedListCard;
