import React, { useState, useCallback } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router";
import firebase from "firebase/app";
import { stripeConfig } from "../../config";
import Box from "@mui/material/Box";

import {
  Button,
  CardActions,
  CardContent,
  Grid,
  Card as MuiCard,
  Chip as MuiChip,
  CardHeader as MuiCardHeader,
  Typography,
  LinearProgress,
} from "@mui/material";
import { spacing } from "@mui/system";
import useAuth from "../../hooks/useAuth";
import {
  useCanceledSubscription,
  createPortalLink,
} from "../../hooks/useStripeStatus";
import getStripe, {
  stripeCancelUrl,
  stripeSuccessUrl,
} from "../../utils/stripe";
import { MAX_OPENAI_CREDITS } from "../../constants";
import { CheckoutSession } from "../../types/auth";

const Card = styled(MuiCard)(spacing);

const CardHeader = styled(MuiCardHeader)(spacing);

const Price = styled.div`
  text-align: center;
  padding-bottom: ${(props) => props.theme.spacing(3)};
`;

const Header = styled.div`
  padding: ${(props) => props.theme.spacing(6)} 0;
`;
const Chip = styled(MuiChip)(spacing);

const Pricing: React.FC<unknown> = () => {
  const { user, isAuthenticated, subscription, hasYearlySubscription } =
    useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { hasCanceledSubscriptionInPast } = useCanceledSubscription();
  const isTrialActiveOrDone = hasCanceledSubscriptionInPast || user?.allowTrial;
  const redirectToPortalLink = useCallback(() => {
    if (user?.id) {
      setLoading(true);
      createPortalLink();
    } else {
      navigate("/auth/sign-in");
    }
  }, [user?.id, navigate]);

  const createCheckoutSession = useCallback(
    async (productId: string | undefined) => {
      if (!user?.id) {
        return;
      }
      setLoading(true);
      const firestore = firebase.firestore();

      const checkoutSessionRef = await firestore
        .collection("users")
        .doc(user.id)
        .collection("checkout_sessions")
        .add({
          allow_promotion_codes: true,
          price: productId,
          trial_from_plan: !isTrialActiveOrDone, //https://github.com/stripe/stripe-firebase-extensions/blob/ffccbe78c4cf458f36ea7d0057313f9012b50cd0/firestore-stripe-subscriptions/POSTINSTALL.md#handling-trials
          success_url: stripeSuccessUrl,
          cancel_url: stripeCancelUrl,
        } as CheckoutSession);

      checkoutSessionRef.onSnapshot(async (snap) => {
        const sessionId = snap.data()?.sessionId;
        if (sessionId) {
          const stripe = await getStripe();
          if (stripe) {
            await stripe.redirectToCheckout({ sessionId });
          }
        }
      });
    },
    [user?.id, isTrialActiveOrDone]
  );

  const isUserMonthlySubscribed =
    subscription &&
    subscription.items[0]?.price?.id === stripeConfig.monthlyProductId;

  const isUserYearlySubscribed =
    subscription &&
    subscription.items[0]?.price?.id === stripeConfig.yearlyProductId;

  const isUserNotSubscribed = !subscription;
  const creditsRemaining =
    user && typeof user.openAICreditsCount === "number"
      ? MAX_OPENAI_CREDITS - user.openAICreditsCount
      : 0;
  const creditsInPercent = (creditsRemaining * 100) / MAX_OPENAI_CREDITS;
  return (
    <React.Fragment>
      <Helmet title="Pricing" />
      <Typography variant="h3" gutterBottom display="inline">
        Pricing
      </Typography>
      <Header>
        {loading && <LinearProgress />}

        <Typography variant="h3" gutterBottom align="center">
          We have a plan for everyone
        </Typography>
      </Header>

      <Grid container justifyContent="center">
        <Grid item xs={12} lg={10}>
          <Grid container spacing={6} alignItems="flex-end">
            <Grid item xs={12} md={6}>
              <Card p={5}>
                <CardHeader
                  title="Monthly renewal plan"
                  subheader="Auto-pay scheduled on a monthly basis"
                  titleTypographyProps={{ align: "center" }}
                  subheaderTypographyProps={{ align: "center" }}
                  pb={0}
                  pt={2}
                />
                <CardContent>
                  <Price>
                    <Typography
                      component="h2"
                      variant="h1"
                      color="textPrimary"
                      align="center"
                      display="inline"
                    >
                      $35
                    </Typography>
                    <Typography
                      variant="h5"
                      color="textSecondary"
                      align="center"
                      display="inline"
                    >
                      /month
                    </Typography>
                  </Price>
                  <Typography variant="subtitle1" align="center">
                    Unlimited runs
                    <br />
                    Access to all of our tools
                    <br />
                    Access to our newest features
                    <br />
                    24/7 email support
                  </Typography>
                </CardContent>
                {isUserMonthlySubscribed && (
                  <React.Fragment>
                    {subscription.status === "trialing" && (
                      <Typography variant="subtitle1" align="center">
                        <Chip label="Trial Active" color="success" />
                      </Typography>
                    )}
                    {isAuthenticated && (
                      <CardActions>
                        <Button
                          disabled={loading}
                          onClick={redirectToPortalLink}
                          fullWidth
                          variant="contained"
                          color="primary"
                        >
                          Manage subscription
                        </Button>
                      </CardActions>
                    )}
                  </React.Fragment>
                )}
                <CardActions>
                  {isUserNotSubscribed && isAuthenticated && (
                    <Button
                      disabled={loading}
                      onClick={() => {
                        if (user?.id) {
                          createCheckoutSession(stripeConfig.monthlyProductId);
                        } else {
                          navigate("/auth/sign-in");
                        }
                      }}
                      fullWidth
                      variant="contained"
                      color="primary"
                    >
                      {isTrialActiveOrDone ? "Get started" : "Start free trial"}
                    </Button>
                  )}
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card p={5}>
                <CardHeader
                  title="Annual renewal plan"
                  subheader="Auto-pay scheduled on a yearly basis"
                  titleTypographyProps={{ align: "center" }}
                  subheaderTypographyProps={{ align: "center" }}
                  pb={0}
                  pt={2}
                />
                <CardContent>
                  <Price>
                    <Typography
                      component="h2"
                      variant="h1"
                      color="textPrimary"
                      align="center"
                      display="inline"
                    >
                      $420
                    </Typography>
                    <Typography
                      variant="h5"
                      color="textSecondary"
                      align="center"
                      display="inline"
                    >
                      /year
                    </Typography>
                  </Price>
                  <Typography variant="subtitle1" align="center">
                    Unlimited runs
                    <br />
                    Access to all of our tools
                    <br />
                    Access to our newest features
                    <br />
                    24/7 email support
                  </Typography>
                </CardContent>
                <CardActions>
                  {isUserYearlySubscribed && (
                    <Button
                      disabled={loading}
                      onClick={redirectToPortalLink}
                      fullWidth
                      variant="contained"
                      color="primary"
                    >
                      Manage subscription
                    </Button>
                  )}
                  {isUserNotSubscribed && isAuthenticated && (
                    <Button
                      disabled={loading}
                      onClick={() => {
                        if (user?.id) {
                          createCheckoutSession(stripeConfig.yearlyProductId);
                        } else {
                          navigate("/auth/sign-in");
                        }
                      }}
                      fullWidth
                      variant="contained"
                      color="primary"
                    >
                      Get started
                    </Button>
                  )}
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {(!isUserNotSubscribed || hasYearlySubscription) && isAuthenticated && (
        <Grid container justifyContent="center">
          <Grid item xs={12} md={6}>
            <Card p={5} style={{ background: "none" }}>
              <Typography variant="h5">Credits remaining</Typography>
              <Box sx={{ width: "100%", alignItems: "center", m: 2 }}>
                <LinearProgress
                  variant="determinate"
                  value={creditsInPercent}
                />
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                >{`${creditsRemaining} / ${MAX_OPENAI_CREDITS}`}</Typography>
              </Box>
            </Card>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
};

export default Pricing;
