import firebase from "firebase/app";
import { OnboardingSteps } from "../constants";
import * as Yup from "yup";
import { Subscription as SubscriptionType } from "@stripe/firestore-stripe-payments";

export type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
  ? {
    type: Key;
  }
  : {
    type: Key;
    payload: M[Key];
  };
};

export type AuthUser = null | Record<string, any>;
export type Subscription = null | Partial<SubscriptionType>;

export type AuthState = {
  isAuthenticated: boolean;
  isInitialized: boolean;
  user: AuthUser;
  subscription?: Subscription;
  onBoardingStep?: OnboardingSteps;
  savedResultsCount?: number;
  hasYearlySubscription?: boolean;
};

export type JWTContextType = {
  isAuthenticated: boolean;
  isInitialized: boolean;
  user: AuthUser;
  method: "jwt";
  signIn: (email: string, password: string) => Promise<void>;
  signOut: () => Promise<void>;
  signUp: (
    email: string,
    password: string,
    firstName: string,
    lastName: string
  ) => Promise<void>;
  resetPassword: (email: string) => void;
  confirmResetPassword: (code: string, password: string) => void;
};

export type FirebaseAuthContextType = {
  isAuthenticated: boolean;
  isInitialized: boolean;
  user: AuthUser;
  subscription?: Subscription;
  onBoardingStep?: OnboardingSteps;
  savedResultsCount?: number;
  hasYearlySubscription?: boolean;
  method: "firebase";
  signIn: (email: string, password: string) => Promise<void>;
  signUp: (
    email: string,
    password: string,
    firstName: string,
    lastName: string,
    redirectToCheckout?: boolean,
  ) => Promise<void>;
  signInWithGoogle: (redirectToCheckout?: boolean) => Promise<{ authCred: firebase.auth.AuthCredential | null; isNewUser: boolean | undefined }>;
  signInWithFaceBook: () => Promise<firebase.auth.UserCredential>;
  signInWithTwitter: () => Promise<firebase.auth.UserCredential>;
  signOut: () => Promise<void>;
  resetPassword: (email: string) => Promise<void | string>;
  confirmResetPassword: (code: string, password: string) => Promise<void | string>;
  addSavedResultsCount: (count: number) => void;
  updateProfile: (updatedProfile: {
    uid: string;
    firstName?: string;
    lastName?: string;
    openAICreditsCount?: number;
    onBoardingStep?: OnboardingSteps;
    allowTrial?: boolean;
    trialEnd?: firebase.firestore.Timestamp;
  }) => Promise<void>;
};

export type Auth0ContextType = {
  isAuthenticated: boolean;
  isInitialized: boolean;
  user: AuthUser;
  method: "auth0";
  signIn: () => Promise<void>;
  signOut: VoidFunction;
  resetPassword: (email: string) => void;
  confirmResetPassword: (code: string, password: string) => void;
};

export type CognitoContextType = {
  isAuthenticated: boolean;
  isInitialized: boolean;
  user: AuthUser;
  method: "cognito";
  signIn: (email: string, password: string) => Promise<unknown>;
  signUp: (
    email: string,
    password: string,
    firstName: string,
    lastName: string
  ) => Promise<unknown>;
  signOut: VoidFunction;
  resetPassword: (email: string) => void;
  confirmResetPassword: (code: string, password: string) => void;
};

export const specialUserSchema = Yup.object({
  email: Yup.string().required().email(),
});

export type SpecialUser = Yup.InferType<typeof specialUserSchema>;

export type CheckoutSession = {
  allow_promotion_codes: boolean;
  trial_from_plan: boolean;
  price: string;
  success_url: string;
  cancel_url: string;
  metadata: {
    // send uid to subscription document
    firebaseUID: string;
  };
  url: string;
  created: firebase.firestore.Timestamp;
};
