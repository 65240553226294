import React, { useEffect, useState, useCallback } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Divider as MuiDivider,
  Grid,
  Button as MuiButton,
  CircularProgress as LinearProgress,
  Typography as MuiTypography,
} from "@mui/material";

import { spacing, SpacingProps } from "@mui/system";
import useAuth from "../../hooks/useAuth";
import firebase from "firebase/app";
import { SavedResult } from "./ListDetails";
import SavedListsSettingsCard from "../../components/lists/ListsSettingsCard";

const Divider = styled(MuiDivider)(spacing);

const Button = styled(MuiButton)(spacing);

const Typography = styled(MuiTypography)<SpacingProps>(spacing);

export interface ISavedList {
  /**
   * Name of the saved list
   */
  name: string;
  /**
   * This has been removed at the moment while creating a new saved list, but needs to support old list implementation
   */
  url?: string;
  /**
   * The firebase doc id of the saved list
   */
  id: string;
  /**
   * This contains the copy results that were saved under the current saved list
   */
  results: SavedResult[];
  /**
   * This boolean flag is used for toggling view of extra textfield i.e. used for creating new list
   */
  notCreatedYet: boolean;
}

/**
 * SavedListsSettings is the component that displays the different created lists for a user
 */
const SavedListsSettings: React.FC<Record<string, never>> = () => {
  const [savedLists, setSavedLists] = useState<ISavedList[]>([]);
  const [isFetchListsInProgress, setIsFetchListsInProgress] = useState(true);
  const [isCreateNewList, setIsCreateNewList] = useState(false);
  const { user, addSavedResultsCount } = useAuth();

  const deleteSavedList = useCallback(
    async (savedListId: string) => {
      const deletingSavedListDocRef = await user?.doc
        ?.collection("projects")
        .doc(savedListId);

      await deletingSavedListDocRef
        .collection("savedResults")
        ?.get()
        .then((resultsSnapshot: firebase.firestore.QuerySnapshot) => {
          const results: SavedResult[] = [];
          resultsSnapshot.forEach((resultDoc) => {
            results.push({
              ...resultDoc.data(),
              id: resultDoc.id,
            } as SavedResult);
          });
          const removeResultsCount = results.filter(
            (result) => !result.isDeleted
          ).length;
          /** side-effect for removing {removeResultsCount} from savedResultsCount in local store */
          addSavedResultsCount(0 - removeResultsCount);
        });

      deletingSavedListDocRef.delete().then(() => {
        setSavedLists((prevList) => [
          ...prevList.filter((savedList) => savedList.id !== savedListId),
        ]);
      });
    },
    [user?.doc, addSavedResultsCount]
  );
  const updateSavedList = useCallback(
    async (savedListId: string, savedListName: string) => {
      await user?.doc.collection("projects").doc(savedListId).update({
        name: savedListName,
      });
    },
    [user?.doc]
  );

  const createSavedList = useCallback(
    async (savedList: ISavedList) => {
      try {
        const docId = await user?.doc.collection("projects").doc().id;
        await user?.doc.collection("projects").doc(docId).set({
          name: savedList.name,
        });
        setSavedLists((prevSavedList) => {
          return [
            { ...savedList, notCreatedYet: false, id: docId },
            ...prevSavedList,
          ];
        });
        setIsCreateNewList(false);
      } catch (e) {
        setIsCreateNewList(false);
      }
    },
    [user?.doc]
  );

  useEffect(() => {
    async function getSavedLists() {
      try {
        let newSavedLists: ISavedList[] = [];
        await user?.doc
          ?.collection("projects")
          .get()
          .then(async (querySnapshot: firebase.firestore.QuerySnapshot) => {
            await querySnapshot.forEach(async (doc) => {
              await doc.ref
                .collection("savedResults")
                ?.get()
                .then((resultsSnapshot: firebase.firestore.QuerySnapshot) => {
                  const results: SavedResult[] = [];
                  resultsSnapshot.forEach((resultDoc) => {
                    results.push({
                      ...resultDoc.data(),
                      id: resultDoc.id,
                    } as SavedResult);
                  });
                  newSavedLists = [
                    ...newSavedLists,
                    {
                      ...doc.data(),
                      results: results.filter((result) => !result.isDeleted),
                      id: doc.id,
                    } as ISavedList,
                  ];
                  setSavedLists(newSavedLists);
                });
            });
            setIsFetchListsInProgress(false);
          });
      } catch (e) {
        setIsFetchListsInProgress(false);
      }
    }
    if (user?.doc) {
      getSavedLists();
    }
  }, [user?.doc]);

  return (
    <>
      <Helmet title="Edit Lists" />

      <Typography variant="h3" gutterBottom display="inline">
        Edit Lists
      </Typography>
      <Button
        sx={{
          float: "right",
        }}
        variant="contained"
        color="primary"
        onClick={() => setIsCreateNewList(true)}
        disabled={isFetchListsInProgress ?? isCreateNewList}
      >
        CREATE NEW LIST
      </Button>
      <Divider my={6} />
      <Grid container alignItems="center">
        {isFetchListsInProgress ? (
          <LinearProgress />
        ) : (
          <Grid container spacing={6}>
            {isCreateNewList && (
              <SavedListsSettingsCard
                key="saved-list-settings-new"
                savedList={{
                  name: "",
                  id: "",
                  results: [],
                  url: "",
                  notCreatedYet: true,
                }}
                createSavedList={createSavedList}
                deleteSavedList={() => setIsCreateNewList(false)}
              />
            )}
            {savedLists.length > 0 ? (
              savedLists.map((p) => (
                <SavedListsSettingsCard
                  key={`saved-list-settings-${p.id}`}
                  savedList={p}
                  deleteSavedList={deleteSavedList}
                  updateSavedList={updateSavedList}
                />
              ))
            ) : (
              <Grid item xs={12} lg={12} xl={12}>
                <Typography variant="h5">No lists available</Typography>
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default SavedListsSettings;
