import React from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import ClearIcon from "@mui/icons-material/Clear";

import useAuth from "../../../hooks/useAuth";
import { ReactComponent as InfoIcon } from "../../../vendor/assets/info.svg";
import { useTrack } from "../../../hooks/useSegment";
import { Button, FormHelperText, TextField, AdornmentIconButton } from "./Style";

const AUTH_USER_NOT_FOUND_ERROR = "auth/user-not-found";
const AUTH_INVALID_EMAIL_ERROR = "auth/invalid-email";

const initialValues = { email: "" };

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please enter a valid email address")
    .max(255, "Your email must contain no more than 255 characters")
    .required("Please enter a valid email address"),
});

type ForgotPasswordFormProps = {
  onSubmitted: () => void;
}

export const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = ({ onSubmitted }) => {
  const { resetPassword } = useAuth();
  const { track } = useTrack();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, { setErrors, setSubmitting }) => {
        try {
          track("Auth - Form Send email Btn Clicked", {
            email: values.email,
            action: "click",
            buttonType: "send-email-button",
          });
          await resetPassword(values.email);
          onSubmitted();
        } catch (error: any) {
          if (error?.code === AUTH_USER_NOT_FOUND_ERROR) {
            // According to design: "An email with password reset instructions has been sent to your email address,
            // *if it exists on our system*", so we don't show "User not found" error message
            return onSubmitted();
          }
          // Not using fetchApiErrorMsg(error) because the final message may be too large and it is not clear what
          // content will be displayed to the user. At the same time, we handle both main cases: invalid input and
          // "user not found" error
          setErrors({
            email: error?.code === AUTH_INVALID_EMAIL_ERROR ?
              "Please enter a valid email address" :
              "Something went wrong"
          });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        handleReset
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          <TextField
            type="email"
            name="email"
            placeholder="Email address"
            id="outlined-size-medium"
            size="medium"
            value={values.email}
            error={Boolean(touched.email && errors.email)}
            onBlur={handleBlur}
            onChange={handleChange}
            fullWidth
            InputProps={{
              endAdornment: (
                <AdornmentIconButton $isHidden={!values.email} onClick={() => handleReset()}>
                  <ClearIcon />
                </AdornmentIconButton>
              ),
            }}
          />
          {touched.email && errors.email && <FormHelperText><InfoIcon />{errors.email}</FormHelperText>}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={isSubmitting}
          >
            Send Email
          </Button>
        </form>
      )}
    </Formik>
  );
}
