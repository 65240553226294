import { createContext, ReactNode, useEffect, useState } from "react";
import {
  FirebaseAuthContextType,
  ActionMap,
  AuthState,
  AuthUser,
  Subscription,
  SpecialUser,
  specialUserSchema,
  CheckoutSession,
} from "../types/auth";
import { User } from "../types/user";
import { firebase } from "../utils/firebase";
import { configProvider, stripeConfig } from "../config";
import getStripe, { stripeCancelUrl, stripeSuccessUrl } from "../utils/stripe";
import { getUserTypes, OnboardingSteps } from "../constants";
import usePersistedReducer from "../hooks/usePersistedReducer";
import LogRocket from "logrocket";
import { updateIntercom, restartIntercom } from "../utils/intercom";
import { SavedResult } from "../pages/pages/ListDetails";
import { identify, IdentifyProps, track } from "../hooks/useSegment";
LogRocket.init("djavui/compose-web-dev");

const INITIALIZE = "INITIALIZE";
const SUBSCRIPTION = "SUBSCRIPTION";
const ONBOARDING = "ONBOARDING";
const RESULTS_COUNT = "RESULTS_COUNT";
const SPECIAL_USER_GROUP = "SPECIAL_USER_GROUP";

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  onBoardingStep: null,
  subscription: null,
  savedResultsCount: 0,
  hasYearlySubscription: false,
};

type AuthActionTypes = {
  [INITIALIZE]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
  [SUBSCRIPTION]: {
    subscription?: Subscription;
  };
  [ONBOARDING]: {
    onBoardingStep: OnboardingSteps;
  };
  [RESULTS_COUNT]: {
    savedResultsCount: number;
  };
  SPECIAL_USER_GROUP: {
    /**
     * This is the flag which holds true for those users who were granted yearly subscription
     * For now we have not added any condition to cancel their subscription if the year ends.
     * So even though the name suggests year as a tenure, it is unlimited unless we remove their email ids from firebase remote config.
     * Reference: https://linear.app/composeai/issue/COM-1716/grant-yearly-subscription-to-special-accounts-on-compose-web
     */
    hasYearlySubscription: boolean;
  };
};

type FirebaseActions =
  ActionMap<AuthActionTypes>[keyof ActionMap<AuthActionTypes>];

const reducer = (state: AuthState, action: FirebaseActions) => {
  if (action.type === INITIALIZE) {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  }
  if (action.type === SUBSCRIPTION) {
    const { subscription } = action.payload;
    return {
      ...state,
      subscription,
    };
  }
  if (action.type === ONBOARDING) {
    const { onBoardingStep } = action.payload;
    return {
      ...state,
      onBoardingStep,
    };
  }
  if (action.type === SPECIAL_USER_GROUP) {
    const { hasYearlySubscription } = action.payload;
    return {
      ...state,
      hasYearlySubscription,
    };
  }
  if (action.type === RESULTS_COUNT) {
    const { savedResultsCount } = action.payload;
    return {
      ...state,
      savedResultsCount: (state?.savedResultsCount ?? 0) + savedResultsCount,
    };
  }

  return state;
};

const AuthContext = createContext<FirebaseAuthContextType | null>(null);

interface AuthProviderProps {
  children: ReactNode;
}

const updateNameInIntercom = (user: AuthUser) => {
  if (user?.firstName) {
    updateIntercom({
      name: user.firstName,
    });
  }
};

/**
 * This function tracks identify message, and logrocket session url.
 * It gets called whenever the auth state changes for current user
 */
const trackOnAuthStateChanged = (
  userDoc: AuthUser,
  hasYearlySubscription: boolean
) => {
  if (userDoc && userDoc.uid && userDoc.email) {
    const name =
      userDoc.displayName ||
      (userDoc.firstName &&
        userDoc.lastName &&
        `${userDoc.firstName} ${userDoc.lastName}`);

    identify(userDoc.uid, userDoc.email, {
      ...getUserTypes(userDoc, hasYearlySubscription),
      name: name,
    });

    LogRocket.getSessionURL(function (sessionURL) {
      track("LogRocket - Compose Web app", {
        sessionURL: sessionURL,
        email: userDoc.email,
        uid: userDoc.uid,
        name: name,
      });
    });
    LogRocket.identify(userDoc.uid);
  }
};

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [profile, setProfile] = useState<
    firebase.firestore.DocumentData | undefined
  >();

  const { composeWebAllowFreeTrialWithoutCheckout } = configProvider.useFlags();

  const { state, dispatch } = usePersistedReducer(
    reducer,
    initialState,
    "ROOT"
  );

  useEffect(
    () =>
      firebase.auth().onAuthStateChanged(async (user) => {
        if (user) {
          const docRef = firebase.firestore().collection("users").doc(user.uid);
          docRef
            .get()
            .then(async (doc) => {
              if (doc.exists) {
                const userDoc = doc.data();
                setProfile(userDoc);

                updateNameInIntercom(userDoc as AuthUser);

                docRef
                  ?.collection("subscriptions")
                  .where("status", "in", ["trialing", "active"])
                  .onSnapshot(
                    async (snapshot: firebase.firestore.QuerySnapshot) => {
                      // In this implementation we only expect one active or trialing subscription to exist.
                      const subscriptionDoc = snapshot.docs[0];
                      dispatch({
                        type: SUBSCRIPTION,
                        payload: {
                          subscription: subscriptionDoc?.data(),
                        },
                      });
                    }
                  );
                docRef
                  ?.collection("projects")
                  .get()
                  .then(
                    async (querySnapshot: firebase.firestore.QuerySnapshot) => {
                      const docPromises: Promise<void>[] = [];
                      await querySnapshot.forEach((doc) => {
                        doc.ref
                          .collection("savedResults")
                          ?.get()
                          .then(
                            (
                              resultsSnapshot: firebase.firestore.QuerySnapshot
                            ) => {
                              const results: SavedResult[] = [];
                              resultsSnapshot.forEach((resultDoc) => {
                                results.push({
                                  ...resultDoc.data(),
                                  id: resultDoc.id,
                                } as SavedResult);
                              });
                              dispatch({
                                type: RESULTS_COUNT,
                                payload: {
                                  savedResultsCount: results.filter(
                                    (result) => !result.isDeleted
                                  ).length,
                                },
                              });
                            }
                          );
                      });
                      await Promise.all(docPromises);
                    }
                  );
                dispatch({
                  type: ONBOARDING,
                  payload: {
                    onBoardingStep: userDoc?.onBoardingStep || "done",
                  },
                });

                // remote config for special users
                const special_users_group = await firebase
                  .remoteConfig()
                  .getValue("special_users_group");
                const specialUserGroup: SpecialUser[] = JSON.parse(
                  special_users_group.asString()
                );
                const hasYearlySubscription = specialUserGroup.some(
                  (specialUser: SpecialUser) =>
                    specialUserSchema.isValidSync(specialUser) &&
                    specialUser.email === userDoc?.email
                );

                trackOnAuthStateChanged(
                  userDoc as AuthUser,
                  hasYearlySubscription
                );

                dispatch({
                  type: SPECIAL_USER_GROUP,
                  payload: {
                    hasYearlySubscription,
                  },
                });
              }
            })
            .catch((error) => {
              console.error(error);
            });

          dispatch({
            type: INITIALIZE,
            payload: { isAuthenticated: true, user: { ...user, doc: docRef } },
          });
        } else {
          dispatch({
            type: INITIALIZE,
            payload: { isAuthenticated: false, user: null },
          });
        }
      }),
    [dispatch]
  );

  const signIn = (email: string, password: string) =>
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((res) => {
        track("Auth - User Signed in", {
          email: email || "",
          id: res.user?.uid,
          medium: "compose-web-form-signin",
          name: res.user?.displayName,
        });
        return;
      });

  const signInWithGoogle = async (redirectToCheckout?: boolean) => {
    const provider = new firebase.auth.GoogleAuthProvider();
    return firebase
      .auth()
      .signInWithPopup(provider)
      .then(async (res) => {
        const isNewUser = res.additionalUserInfo?.isNewUser;
        const { uid, email, displayName } = res.user as Partial<User>;
        if (isNewUser) {
          const segmentData = {
            email: email || "",
            id: uid || "",
            medium: "compose-web-google-signup",
            name: displayName || "",
            trialStatus: composeWebAllowFreeTrialWithoutCheckout
              ? "trialStarted"
              : "trialNotStarted",
            trialType: composeWebAllowFreeTrialWithoutCheckout
              ? "trialWithoutCard"
              : "trialWithCard",
            created: Date.now(),
          };
          identify(
            segmentData.id,
            segmentData.email,
            segmentData as IdentifyProps
          );
          track("Auth - User Signed up", segmentData);
          const docRef = firebase.firestore().collection("users").doc(uid);
          const displayNameArr = (displayName && displayName.split(" ")) || [
            "",
          ];
          await docRef.set({
            uid,
            email,
            displayName,
            firstName: displayNameArr[0],
            lastName: displayNameArr[displayNameArr.length - 1],
            onBoardingStep: "subscription",
          } as User);

          if (redirectToCheckout) {
            await postSignUp(docRef, uid as string);
          }
        } else {
          const segmentData = {
            email: email || "",
            id: uid || "",
            medium: "compose-web-google-signin",
            name: displayName || "",
          };
          track("Auth - User Signed in", segmentData);
        }
        return { authCred: res.credential, isNewUser };
      });
  };

  const signInWithFaceBook = () => {
    const provider = new firebase.auth.FacebookAuthProvider();
    return firebase.auth().signInWithPopup(provider);
  };

  const signInWithTwitter = () => {
    const provider = new firebase.auth.TwitterAuthProvider();
    return firebase.auth().signInWithPopup(provider);
  };

  const signUp = (
    email: string,
    password: string,
    firstName: string,
    lastName: string,
    redirectToCheckout?: boolean,
  ) =>
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(async (res) => {
        const name = `${firstName} ${lastName}`;
        const userId = res.user?.uid as string;
        const segmentData = {
          email: email,
          id: userId,
          medium: "compose-web-form-signup",
          trialStatus: composeWebAllowFreeTrialWithoutCheckout
            ? "trialStarted"
            : "trialNotStarted",
          trialType: composeWebAllowFreeTrialWithoutCheckout
            ? "trialWithoutCard"
            : "trialWithCard",
          name: name,
          created: Date.now(),
        };
        identify(
          segmentData.id,
          segmentData.email,
          segmentData as IdentifyProps
        );
        track("Auth - User Signed up", segmentData);
        const docRef = firebase.firestore().collection("users").doc(userId);
        await docRef.set({
          uid: userId,
          email,
          displayName: name,
          firstName,
          lastName,
          onBoardingStep: "subscription",
        } as User);

        if (redirectToCheckout) {
          await postSignUp(docRef, userId);
        }
      })

  /**
   * This Method contains the post successful sign up activities like deciding whether to navigate to stripe checkout or to home page, or setting up trial in firebase if needed.
   */
  const postSignUp = async (
    docRef: firebase.firestore.DocumentReference,
    userId: string
  ) => {
    const projectDocId = docRef.collection("projects").doc().id;
    // No need to await on creating default project/list - it can be done in background
    docRef.collection("projects").doc(projectDocId).set({
      name: "Favorites",
      url: "",
    });

    if (composeWebAllowFreeTrialWithoutCheckout) {
      // set up trial_end period and checkout session i.e. stripe customer
      docRef.collection("checkout_sessions").add({
        allow_promotion_codes: true,
        price: stripeConfig.monthlyProductId,
        success_url: stripeSuccessUrl,
        cancel_url: stripeCancelUrl,
      } as CheckoutSession);
      const date = new Date();
      date.setDate(date.getDate() + 7);
      await docRef.update({
        allowTrial: true,
        trialEnd: new Date(date),
        onBoardingStep: "highlight-tools",
      } as Partial<User>);
      window.location.href = stripeSuccessUrl;
    } else {
      // go to stripe checkout (normal user registration flow)
      const checkoutSessionRef = await firebase
        .firestore()
        .collection("users")
        .doc(userId)
        .collection("checkout_sessions")
        .add({
          allow_promotion_codes: true,
          price: stripeConfig.monthlyProductId,
          success_url: stripeSuccessUrl,
          cancel_url: stripeCancelUrl,
          metadata: {
            firebaseUID: userId,
          },
        } as CheckoutSession);
      checkoutSessionRef.onSnapshot(async (snap) => {
        const sessionId = snap.data()?.sessionId;
        if (sessionId) {
          const stripe = await getStripe();
          if (stripe) {
            await stripe.redirectToCheckout({ sessionId });
          }
        }
      });
    }
  };

  const signOut = async () => {
    await firebase.auth().signOut();
    restartIntercom();
  };

  const resetPassword = async (email: string) => {
    return await firebase
      .auth()
      .sendPasswordResetEmail(email);
  };
  const confirmResetPassword = async (code: string, password: string) => {
    return await firebase
      .auth()
      .confirmPasswordReset(code, password);
  };
  const updateProfile = async (updatedProfile: {
    uid: string;
    firstName?: string;
    lastName?: string;
    openAICreditsCount?: number;
    allowTrial?: boolean;
    trialEnd?: firebase.firestore.Timestamp;
    onBoardingStep?: OnboardingSteps;
  }) => {
    const {
      uid,
      firstName,
      lastName,
      openAICreditsCount = 0,
      onBoardingStep = "done",
    } = {
      ...profile,
      ...updatedProfile,
    };
    const updatedDetails = {
      firstName,
      lastName,
      displayName: `${firstName} ${lastName}`,
      openAICreditsCount,
      onBoardingStep,
    };
    await firebase
      .firestore()
      .collection("users")
      .doc(uid)
      .update(updatedDetails);
    await setProfile({
      ...profile,
      ...updatedDetails,
    });
    updateNameInIntercom(updatedDetails);
    dispatch({
      type: ONBOARDING,
      payload: {
        onBoardingStep: updatedDetails.onBoardingStep || "done",
      },
    });
  };
  const addSavedResultsCount = (count: number) => {
    dispatch({
      type: RESULTS_COUNT,
      payload: {
        savedResultsCount: count,
      },
    });
  };

  const user = { ...state.user };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "firebase",
        user: {
          id: user.uid,
          email: user.email,
          // TODO: Make below user field values using single source(either user or profile)
          avatar: user.avatar || profile?.avatar,
          displayName: user.displayName || profile?.displayName,
          firstName: user.firstName || profile?.firstName,
          lastName: user.lastName || profile?.lastName,
          openAICreditsCount:
            user.openAICreditsCount || profile?.openAICreditsCount,
          role: "user",
          doc: user.doc,
          onBoardingStep:
            user.onBoardingStep || profile?.onBoardingStep || "done",
          allowTrial: user.allowTrial || profile?.allowTrial,
          trialEnd: user.trialEnd || profile?.trialEnd,
        },
        signIn,
        signUp,
        signInWithGoogle,
        signInWithFaceBook,
        signInWithTwitter,
        signOut,
        resetPassword,
        confirmResetPassword,
        updateProfile,
        addSavedResultsCount,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
