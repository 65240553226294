import {
  Mail,
  Package,
  Search,
  Share,
  MessageCircle,
  ThumbsUp,
  ShoppingCart,
} from "react-feather";
import { useLocation } from "react-router";
import { SidebarItemsType } from "../../types/sidebar";

export interface Tool {
  name: string;
  url: string;
  apiType: string;
  fields: ToolField[];
}

export interface ToolField {
  label: string;
  placeholder?: string;
  helperText?: string;
  isMultiLine?: boolean;
  required?: boolean;
}

export interface Section {
  name: string;
  icon: React.FC<unknown>;
  tools: Tool[];
}

export const toolsTree: Section[] = [
  {
    name: "Product",
    icon: Package,
    tools: [
      {
        name: "Product Descriptions",
        url: "tools/product/descriptions",
        apiType: "product_descriptions",
        fields: [
          {
            label: "What is your product called? (Optional)",
            placeholder: "e.g. Compose AI",
          },
          {
            label: "Describe your product",
            placeholder: "e.g. AI writing assistant",
            required: true,
            helperText:
              "Brief description used to generate additional product descriptions",
            isMultiLine: true,
          },
        ],
      },
      {
        name: "Product Name",
        url: "tools/product/name",
        apiType: "product_name",
        fields: [
          {
            label: "Describe your product",
            placeholder: "e.g. AI writing assistant",
            required: true,
            helperText:
              "Brief description of product used to generate suggestions of product name",
            isMultiLine: true,
          },
        ],
      },
    ],
  },
  {
    name: "Blog",
    icon: Share,
    tools: [
      {
        name: "Blog Ideas",
        url: "tools/blog/ideas",
        apiType: "blog_ideas",
        fields: [
          {
            label:
              "Give the name of the product for which the blog idea is needed. (Optional)",
            placeholder: "e.g. Compose AI",
          },
          {
            label: "Describe the product for which the blog idea is needed.",
            placeholder: "e.g. AI writing assistant",
            required: true,
            helperText: "Brief description used to generate ideas for blog",
            isMultiLine: true,
          },
        ],
      },
      {
        name: "Blog Intro",
        url: "tools/blog/intro",
        apiType: "blog_intro",
        fields: [
          {
            label: "What is your blog title? (Optional)",
            placeholder: "e.g. Christmas traditions",
          },
          {
            label: "What is the blog about?",
            placeholder: "e.g. Unique Christmas traditions around the world",
            helperText:
              "Brief description used to generate intro suggestions for blog",
            isMultiLine: true,
            required: true,
          },
        ],
      },
      {
        name: "Blog Title",
        url: "tools/blog/title",
        apiType: "blog_title",
        fields: [
          {
            label:
              "Give the name of the product for which the blog title is needed. (Optional)",
            placeholder: "e.g. Compose AI",
          },
          {
            label: "Describe the product for which the blog title is needed.",
            placeholder: "e.g. AI writing assistant",
            required: true,
            helperText: "Brief description used to generate titles for blog",
            isMultiLine: true,
          },
        ],
      },
      {
        name: "Blog Title - Listicle",
        url: "tools/blog/titlelisticle",
        apiType: "blog_titlelisticle",
        fields: [
          {
            label:
              "Give the name of the product for which the blog title is needed. (Optional)",
            placeholder: "e.g. Compose AI",
          },
          {
            label: "Describe the product for which the blog title is needed.",
            placeholder: "e.g. AI writing assistant",
            required: true,
            helperText:
              "Brief description used to generate titles for blog in Listicle format",
            isMultiLine: true,
          },
        ],
      },
      {
        name: "Keyword Generator",
        url: "tools/blog/keywordgenerator",
        apiType: "keyword_generator",
        fields: [
          {
            label: "What topic are you posting about?",
            placeholder: "e.g. Christmas gift ideas",
            required: true,
            helperText: "Brief description used to generate keywords for topic",
            isMultiLine: true,
          },
        ],
      },
      {
        name: "Bullet Point to Blog Section",
        url: "tools/blog/bullet",
        apiType: "blog_bullet",
        fields: [
          {
            label: "Blog Topic (Optional)",
            placeholder: "e.g. Benefits of a meal delivery subscription",
          },
          {
            label: "What is the main point of the paragraph?",
            placeholder:
              "e.g. Stay on top of weight loss goals with a healthy meal delivery service.",
            required: true,
            helperText:
              "Brief description used to generate full length blog posts",
            isMultiLine: true,
          },
        ],
      },
    ],
  },
  {
    name: "Email",
    icon: Mail,
    tools: [
      {
        name: "Cancellation Email",
        url: "tools/email/cancellation",
        apiType: "email_cancellation",
        fields: [
          {
            label:
              "Give the name of the product for which the cancellation email is needed. (Optional)",
            placeholder: "e.g. Compose AI",
          },
          {
            label:
              "Describe the product for which the cancellation email is needed.",
            placeholder: "e.g. AI writing assistant",
            required: true,
            helperText:
              "Brief description used to generate suggestions for subscription cancellation email",
            isMultiLine: true,
          },
        ],
      },
      {
        name: "Catchy Email Subject Lines",
        url: "tools/email/subjects",
        apiType: "email_subjects",
        fields: [
          {
            label:
              "Give the name of the product for which the email subject is needed. (Optional)",
            placeholder: "e.g. Compose AI",
          },
          {
            label:
              "Describe the product for which the email subject is needed.",
            placeholder: "e.g. AI writing assistant",
            required: true,
            helperText:
              "Brief description used to generate suggestions for email subject lines",
            isMultiLine: true,
          },
        ],
      },
      {
        name: "Follow Up Email",
        url: "tools/email/followup",
        apiType: "email_followup",
        fields: [
          {
            label: "What is the email for?",
            placeholder: "e.g. received a request for customer support",
            required: true,
            helperText:
              "Brief description of email body to generate follow-up messages",
          },
        ],
      },
      {
        name: "Thank You Note",
        url: "tools/email/thankyounote",
        apiType: "email_thankyounote",
        fields: [
          {
            label: "Who is the message for? (Optional)",
            placeholder: "e.g. Joe Smith",
          },
          {
            label: "What would you like to thank them for?",
            placeholder: "e.g. received a gift",
            required: true,
            helperText:
              "Brief reason for thank you note to generate suggestions",
            isMultiLine: true,
          },
        ],
      },
      {
        name: "Welcome Email",
        url: "tools/email/welcome",
        apiType: "email_welcome",
        fields: [
          {
            label:
              "Give the name of the product for which the welcome email is needed. (Optional)",
            placeholder: "e.g. Compose AI",
          },
          {
            label:
              "Describe the product for which the welcome email is needed.",
            placeholder: "e.g. AI writing assistant",
            required: true,
            helperText:
              "Brief description used to generate suggestions for welcome email",
            isMultiLine: true,
          },
        ],
      },
      {
        name: "Confirmation Email",
        url: "tools/email/confirmation",
        apiType: "email_confirmation",
        fields: [
          {
            label: "What is the email for?",
            placeholder: "e.g. received request for customer support",
            required: true,
            helperText:
              "Brief description of email to generate confirmation messages",
          },
        ],
      },
    ],
  },
  {
    name: "Digital Ad Copy",
    icon: Search,
    tools: [
      {
        name: "Ad Copy Variants",
        url: "tools/ads/variants",
        apiType: "ad_copy_variants",
        fields: [
          {
            label: "Product / Brand Name (Optional)",
            placeholder: "e.g. Compose AI",
          },
          {
            label: "Describe your product",
            placeholder: "e.g. AI writing assistant",
            required: true,
            helperText:
              "Brief description used to generate variants of ad copy",
            isMultiLine: true,
          },
        ],
      },
    ],
  },
  {
    name: "Startup",
    icon: MessageCircle,
    tools: [
      {
        name: "Brand Mission",
        url: "tools/brand/mission",
        apiType: "brand_mission",
        fields: [
          {
            label: "Product / Brand Name (Optional)",
            placeholder: "e.g. Compose AI",
          },
          {
            label: "Describe your product",
            placeholder: "e.g. AI writing assistant",
            required: true,
            helperText:
              "Brief description used to generate mission statements for the brand",
            isMultiLine: true,
          },
        ],
      },
      {
        name: "Brand Voice",
        url: "tools/brand/voice",
        apiType: "brand_voice",
        fields: [
          {
            label: "Product / Brand Name (Optional)",
            placeholder: "e.g. Compose AI",
          },
          {
            label: "Describe your product",
            placeholder: "e.g. AI writing assistant",
            required: true,
            helperText:
              "Brief description used to generate suggestions for the voice of brand",
            isMultiLine: true,
          },
        ],
      },
      {
        name: "Value Proposition",
        url: "tools/value/proposition",
        apiType: "value_proposition",
        fields: [
          {
            label: "What is your business name? (Optional)",
            placeholder: "e.g. Fit Pocket",
          },
          {
            label: "Describe your product",
            placeholder: "e.g. Workout and meal plan app",
            required: true,
            helperText:
              "Brief description used to generate paragraphs highlighting the value of your company/product",
            isMultiLine: true,
          },
        ],
      },
    ],
  },
  {
    name: "Website Copy",
    icon: Search,
    tools: [
      {
        name: "Landing Page Hero Text",
        url: "tools/websitecopy/landingpageherotext",
        apiType: "landingpage_herotext",
        fields: [
          {
            label: "Product / Brand Name (Optional)",
            placeholder: "e.g. Compose AI",
          },
          {
            label: "Describe your product",
            placeholder: "e.g. AI writing assistant",
            required: true,
            helperText:
              "Brief description used to generate landing page hero sections",
            isMultiLine: true,
          },
        ],
      },
      {
        name: "Listicle",
        url: "tools/websitecopy/listicle",
        apiType: "listicle",
        fields: [
          {
            label: "What is your topic? (Optional)",
            placeholder: "e.g. Scary friday night",
          },
          {
            label: "What would you like to create a list for?",
            placeholder: "e.g. Top 10 scariest movies of all time",
            required: true,
            helperText:
              "Brief description used to generate suggestions in a list format",
            isMultiLine: true,
          },
        ],
      },
      {
        name: "Testimonial Rewriter",
        url: "tools/websitecopy/testimonialrewriter",
        apiType: "testimonial_rewriter",
        fields: [
          {
            label: "Product / Brand Name (Optional)",
            placeholder: "e.g. Compose AI",
          },
          {
            label: "Your testimonial",
            required: true,
            helperText: "Brief description used to generate testimonials",
            placeholder:
              "e.g. I spend a lot of time writing: writing emails, product specs, etc etc. Anything that saves me time, like Compose AI, is a gamechanger.",
            isMultiLine: true,
          },
        ],
      },
    ],
  },
  {
    name: "Social Media",
    icon: ThumbsUp,
    tools: [
      {
        name: "Hashtag Generator",
        url: "tools/socialmedia/hashtaggenerator",
        apiType: "hashtag_generator",
        fields: [
          {
            label: "What is your post about?",
            placeholder: "e.g. Christmas gift ideas",
            required: true,
            helperText: "Brief description of your post to generate hashtags",
            isMultiLine: true,
          },
        ],
      },
      {
        name: "Hook Generator",
        url: "tools/socialmedia/hookgenerator",
        apiType: "hook_generator",
        fields: [
          {
            label: "What is your topic?",
            placeholder: "e.g. plant-based meal plan",
            required: true,
            helperText: "Brief description of your topic to generate hooks",
            isMultiLine: true,
          },
        ],
      },
      {
        name: "Launch Your Product",
        url: "tools/socialmedia/launchproduct",
        apiType: "launch_product",
        fields: [
          {
            label: "Product / Brand Name (Optional)",
            placeholder: "e.g. Compose AI",
          },
          {
            label: "Describe your product",
            placeholder: "e.g. AI writing assistant",
            required: true,
            helperText:
              "Brief description used to generate product launch statements",
            isMultiLine: true,
          },
        ],
      },
      {
        name: "Video Call to Action",
        url: "tools/socialmedia/videocalltoaction",
        apiType: "video_call_to_action",
        fields: [
          {
            label: "What is your video title? (Optional)",
            placeholder: "e.g. Recipes",
          },
          {
            label: "What is your video about?",
            required: true,
            helperText:
              "Brief description of your video to generate calls to action",
            placeholder: "e.g. sangria recipe",
            isMultiLine: true,
          },
        ],
      },
      {
        name: "YouTube Description Intro",
        url: "tools/socialmedia/youtubedescriptionintro",
        apiType: "youtube_description_intro",
        fields: [
          {
            label: "What is your video title? (Optional)",
            placeholder: "e.g. Recipes",
          },
          {
            label: "What is your video about?",
            required: true,
            helperText:
              "Brief description of your video to generate additional video descriptions",
            placeholder: "e.g. sangria recipe",
            isMultiLine: true,
          },
        ],
      },
      {
        name: "YouTube Video Title",
        url: "tools/socialmedia/youtubevideotitle",
        apiType: "youtube_video_title",
        fields: [
          {
            required: true,
            label: "What is your video about?",
            placeholder: "e.g. sangria recipe",
            helperText: "Brief description of your video to generate titles",
            isMultiLine: true,
          },
        ],
      },
    ],
  },
  {
    name: "Sales Copy",
    icon: ShoppingCart,
    tools: [
      {
        name: "Attention-Interest-Desire-Action",
        url: "tools/salescopy/attentionaction",
        apiType: "attentioninterest_desireaction",
        fields: [
          {
            label: "Product / Brand Name (Optional)",
            placeholder: "e.g. Compose AI",
          },
          {
            label: "Describe your product",
            placeholder: "e.g. AI writing assistant",
            required: true,
            helperText:
              "Brief description used to generate paragraphs for headings: Attention, Interest, Desire and Action",
            isMultiLine: true,
          },
        ],
      },
      {
        name: "Before-After-Bridge",
        url: "tools/salescopy/beforeafterbridge",
        apiType: "beforeafter_bridge",
        fields: [
          {
            label: "Product / Brand Name (Optional)",
            placeholder: "e.g. Compose AI",
          },
          {
            label: "Describe your product",
            placeholder: "e.g. AI writing assistant",
            required: true,
            helperText:
              "Brief description used to generate paragraphs for headings: Before, After, and Bridge",
            isMultiLine: true,
          },
        ],
      },
      {
        name: "Feature to Benefits",
        url: "tools/salescopy/featurebenefits",
        apiType: "feature_benefits",
        fields: [
          {
            label: "Product / Brand Name (Optional)",
            placeholder: "e.g. Compose AI",
          },
          {
            label: "Describe your product",
            placeholder: "e.g. AI writing assistant",
            required: true,
            helperText:
              "Brief description used to generate features/benefits of your brand",
            isMultiLine: true,
          },
        ],
      },
      {
        name: "Feature-Advantage-Benefit",
        url: "tools/salescopy/featureadvantagebenefits",
        apiType: "feature_advantage_benefits",
        fields: [
          {
            label: "Product / Brand Name (Optional)",
            placeholder: "e.g. Compose AI",
          },
          {
            label: "Describe your product",
            placeholder: "e.g. AI writing assistant",
            required: true,
            helperText:
              "Brief description used to generate paragraphs for headings: Feature, Advantage and Benefit",
            isMultiLine: true,
          },
        ],
      },
      {
        name: "Marketing Angles",
        url: "tools/salescopy/marketingangles",
        apiType: "marketing_angles",
        fields: [
          {
            label: "Product / Brand Name (Optional)",
            placeholder: "e.g. Compose AI",
          },
          {
            label: "Describe your product",
            placeholder: "e.g. AI writing assistant",
            required: true,
            helperText:
              "Brief description used to generate paragraphs from marketing angle",
            isMultiLine: true,
          },
        ],
      },
      {
        name: "Pain-Agitate-Solution",
        url: "tools/salescopy/painagitatesolution",
        apiType: "painagitate_solution",
        fields: [
          {
            label: "Product / Brand Name (Optional)",
            placeholder: "e.g. Compose AI",
          },
          {
            label: "Describe your product",
            placeholder: "e.g. AI writing assistant",
            required: true,
            helperText:
              "Brief description used to generate paragraphs for headings: Pain, Agitate, and Solution",
            isMultiLine: true,
          },
        ],
      },
      {
        name: "Problem-Promise-Proof-Proposal",
        url: "tools/salescopy/problemproposal",
        apiType: "problempromise_proofproposal",
        fields: [
          {
            label: "Product / Brand Name (Optional)",
            placeholder: "e.g. Compose AI",
          },
          {
            label: "Describe your product",
            placeholder: "e.g. AI writing assistant",
            required: true,
            helperText:
              "Brief description used to generate paragraphs for headings: Problem, Promise, Proof, and Proposal",
            isMultiLine: true,
          },
        ],
      },
      {
        name: "QUEST Copywriting",
        url: "tools/salescopy/questcopywriting",
        apiType: "quest_copywriting",
        fields: [
          {
            label: "Product / Brand Name (Optional)",
            placeholder: "e.g. Compose AI",
          },
          {
            label: "Describe your product",
            placeholder: "e.g. AI writing assistant",
            required: true,
            helperText:
              "Brief description used to generate paragraphs for headings: Qualify, Understand, Educate, Stimulate, and Transition",
            isMultiLine: true,
          },
        ],
      },
    ],
  },
];

export const tools = toolsTree.flatMap((section) => section.tools);

export const useToolsSection = (): Array<SidebarItemsType> => {
  const { pathname } = useLocation();
  return toolsTree.map((section) => ({
    href: "",
    icon: section.icon,
    title: section.name,
    children: section.tools.map((tool) => ({
      href:
        pathname.indexOf("savedlists") > -1
          ? tool.url
          : `savedlists/${tool.url}`,
      title: tool.name,
    })),
  })) as SidebarItemsType[];
};
