import React from "react";
import { Outlet } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { ThemeProvider } from "styled-components/macro";
import { Typography, CssBaseline, ThemeProvider as MuiThemeProvider } from "@mui/material";

import { THEMES } from "../../../constants";
import createTheme from "../../../theme";
import GlobalStyle from "../../../components/GlobalStyle";
import ComposeLogo from "../../../vendor/assets/logo_dark.webp";
import { MainRoot, Root, AppContent, ExtAuthContainer, ExtAuthBox, Brand, FormWrapper } from "./Style";

type LayoutProps = {
  helmetTitle: string,
  title: string,
  description: string,
  children: React.ReactElement,
}

export const Layout: React.FC<LayoutProps> = ({ helmetTitle, title, description, children }) => {
  return (
    <MainRoot>
      <MuiThemeProvider theme={createTheme(THEMES.EXT_AUTH)}>
        <ThemeProvider theme={createTheme(THEMES.EXT_AUTH)}>
          <Root>
            <CssBaseline />
            <GlobalStyle />
            <AppContent>
              <ExtAuthContainer>
                <Helmet title={helmetTitle} />
                <Brand src={ComposeLogo} />
                <ExtAuthBox>
                  <Typography component="h1" variant="h1" align="center" gutterBottom>
                    {title}
                  </Typography>
                  <Typography component="span" mb="40px">
                    {description}
                  </Typography>
                  <FormWrapper>
                    {children}
                  </FormWrapper>
                </ExtAuthBox>
              </ExtAuthContainer>
              <Outlet />
            </AppContent>
          </Root>
        </ThemeProvider>
      </MuiThemeProvider>
    </MainRoot>
  );
};
