import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { ForgotPasswordForm } from "../../../components/auth/chromeExtension/ForgotPasswordForm";
import { Layout } from "../../../components/auth/chromeExtension/Layout";
import { BackLink, Button, BackNavLink } from "../../../components/auth/chromeExtension/Style";
import { IS_PROD } from "../../../constants";
import { ROUTE } from "../../../routes";

const SIGN_IN_URL = IS_PROD ? "https://www.compose.ai/login-4" : undefined;

const ForgotPassword: React.FC<unknown> = () => {
  const navigate = useNavigate();

  const [isSubmitted, setIsSubmitted] = useState(false);

  const goToSignIn = () => {
    navigate(ROUTE.SIGN_IN);
  }

  return (
    <Layout
      helmetTitle="Reset Password"
      title={isSubmitted ? "Check your inbox" : "Password recovery"}
      description={
        isSubmitted ?
          "An email with password reset instructions has been sent to your email address, if it exists on our system." :
          "Please provide email that you used when you signed up for your Compose AI account. We will send you an email that will allow you to reset your password."
      }
    >
      {isSubmitted ? (
        <Button
          href={SIGN_IN_URL}
          type="button"
          fullWidth
          variant="contained"
          onClick={goToSignIn}
        >
          Back to Sign in
        </Button>
      ) : (
        <>
          <ForgotPasswordForm onSubmitted={() => setIsSubmitted(true)} />
          {IS_PROD && <BackLink href={SIGN_IN_URL}>Back to Sign in</BackLink>}
          {!IS_PROD && <BackNavLink to={ROUTE.SIGN_IN}>Back to Sign in</BackNavLink>}
        </>
      )}
    </Layout>
  );
};

export default ForgotPassword;
