import React from "react";
import {
  ProviderConfig,
  useFlags,
  withLDProvider,
} from "launchdarkly-react-client-sdk";

/**
 * Helper to assert something is non-null.  Blatantly copied from compose-extension/src/ts/common/helpers.ts
 *
 * Like '!' operator, but doesn't make linter angry since it explicitly
 * checks for null/undefined and throws an error at call site if encountered.  '!' operator basically just says,
 * "assume this won't be null/undefined but don't bother checking at runtime".
 *
 * @param maybeNull Thing that might be null/undefined.
 * @param name Name of thing being checked, used to generate error message.
 * @returns Thing that is definitely not null/undefined.
 */
export function assertNonNull<T>(maybeNull: T, name: string): NonNullable<T> {
  if (maybeNull === null) {
    throw new Error(`${name} was null`);
  } else if (maybeNull === undefined) {
    throw new Error(`${name} was undefined`);
  } else {
    return maybeNull as NonNullable<T>;
  }
}

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const auth0Config = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
};

export const cognitoConfig = {
  userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  clientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
};

export const apiConfig = {
  url: assertNonNull(
    process.env.REACT_APP_GENERATE_URL,
    "REACT_APP_GENERATE_URL"
  ),
};

/**
 * This contains the config params like API public key, product ids, and extension API route for stripe in specific env
 */
export const stripeConfig = {
  /**
   * this is the public api key for stripe
   */
  key: process.env.REACT_APP_STRIPE_KEY,

  /**
   * this is the stripe product id for annual subscription
   */
  yearlyProductId: process.env.REACT_APP_STRIPE_PRODUCT_YEARLY,

  /**
   * this is the stripe product id for monthly subscription
   */
  monthlyProductId: process.env.REACT_APP_STRIPE_PRODUCT_MONTHLY,

  /**
   * this contains a part of the API routes for stripe extension
   */
  extensionRoute: process.env.REACT_APP_STRIPE_EXTENSION_ROUTE,
};

export const LaunchDarklyConfig = {
  /**
   * this contains LaunchDarkly client-side id
   */
  clientSideID: process.env.REACT_APP_LD_CLIENT_SIDE_ID,
};

export const isProd = window.location.hostname === "app.compose.ai";

interface ComposeFlags {
  /**
   * this flag decides whether user should be navigated to stripe checkout for starting free trial on registration or not
   */
  composeWebAllowFreeTrialWithoutCheckout: boolean;
}

/**
 * A provider and hook to receive configuration values from
 */
interface ConfigProvider<Flags = ComposeFlags> {
  withConfigProvider: (component: React.ComponentType) => React.ComponentType;
  useFlags: () => Partial<Flags>;
}

const LDConfigProvider: ConfigProvider = {
  withConfigProvider: withLDProvider({
    clientSideID: LaunchDarklyConfig.clientSideID,
    user: {
      anonymous: true,
    },
  } as ProviderConfig),
  useFlags: () => useFlags() as Partial<ComposeFlags>,
};

const HardCodedConfigProvider: ConfigProvider = {
  withConfigProvider: (component) => component,
  useFlags: () => ({ composeWebAllowFreeTrialWithoutCheckout: false }),
};

export const configProvider: ConfigProvider = HardCodedConfigProvider;
