import * as React from "react";
import { Navigate, useLocation } from "react-router-dom";
import WithDialog from "../../hoc/WithDialog";

import useAuth from "../../hooks/useAuth";

interface AuthGuardProps {
  children: React.ReactNode;
}

// For routes that can only be accessed by authenticated users
const AuthGuard: React.FC<AuthGuardProps> = ({ children }) => {
  const { isAuthenticated, onBoardingStep } = useAuth();
  const location = useLocation();
  if (!isAuthenticated) {
    const redirectURL = `/auth/sign-in?redirectURL=${location.pathname}`;
    return <Navigate to={redirectURL} />;
  } else if (onBoardingStep) {
    return <WithDialog onBoardingStep={onBoardingStep}>{children}</WithDialog>;
  }

  return <>{children}</>;
};

export default AuthGuard;
