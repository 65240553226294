import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";

import useAuth from "../../../hooks/useAuth";
import { ReactComponent as InfoIcon } from "../../../vendor/assets/info.svg";
import { useTrack } from "../../../hooks/useSegment";
import { Button, FormHelperText, TextField, AdornmentIconButton } from "./Style";

const AUTH_INVALID_CODE_ERROR = "auth/invalid-action-code";
const AUTH_ACTION_CODE_PARAM = "oobCode";

const initialValues = {
  password: "",
  passwordConfirmation: "",
};

const getError = (
  touched: Partial<Record<keyof typeof initialValues, boolean>>,
  errors: Partial<Record<keyof typeof initialValues, string>>,
  actionCode?: string | null,
) => (!actionCode && "Reset password link expired or has already been used") ||
  (touched.password && errors.password) ||
  (touched.passwordConfirmation && errors.passwordConfirmation) ||
  null;

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .oneOf([Yup.ref("passwordConfirmation"), null], "The password don't match")
    .min(6, "Your password must contain at least 6 characters")
    .max(255, "Your password must contain no more than 255 characters")
    .required("Your password must contain at least 6 characters"),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref("password"), null], "The password don't match")
    .required("Your password must contain at least 6 characters"),
})

type ResetPasswordFormProps = {
  onSubmitted: () => void;
  onCodeFailed: () => void;
}

export const ResetPasswordForm: React.FC<ResetPasswordFormProps> = ({ onSubmitted, onCodeFailed }) =>  {
  const [queryParams, setSearchParams] = useSearchParams();
  const { confirmResetPassword } = useAuth();
  const { track } = useTrack();
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false);

  const actionCode = queryParams.get(AUTH_ACTION_CODE_PARAM);

  useEffect(() => {
    if (!actionCode) {
      onCodeFailed();
    }
  }, [onCodeFailed, actionCode])

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, { setErrors, setSubmitting }) => {
        try {
          track("Auth - Form reset password Btn Clicked", {
            password: values.password,
            PasswordConfirmation: values.passwordConfirmation,
            action: "click",
            buttonType: "reset-password-in-button",
          });
          if (actionCode) {
            await confirmResetPassword(actionCode, values.password);
            onSubmitted();
          }
        } catch (error: any) {
          if (error?.code === AUTH_INVALID_CODE_ERROR) {
            // There is no requirements what to do if the action code is invalid. Will display error message and
            // "Back to Forgot password" button, also remove action code from query params
            queryParams.delete(AUTH_ACTION_CODE_PARAM);
            setSearchParams(queryParams);
          }
          // Not using fetchApiErrorMsg(error) because the final message may be too large and it is not clear what
          // content will be displayed to the user. At the same time, we handle both main cases: invalid input and
          // "invalid action code" error
          setErrors({ password: "Something went wrong." });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          <TextField
            type={!showPassword ? "password" : "text"}
            name="password"
            value={values.password}
            placeholder="Enter new password"
            error={Boolean(touched.password && errors.password)}
            fullWidth
            onBlur={handleBlur}
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <AdornmentIconButton
                  $isHidden={!values.password}
                  onClick={() => setShowPassword(value => !value)}
                >
                  {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </AdornmentIconButton>
              ),
            }}
          />
          <TextField
            type={!showPasswordConfirmation ? "password" : "text"}
            name="passwordConfirmation"
            placeholder="Confirm new password"
            value={values.passwordConfirmation}
            error={Boolean(touched.passwordConfirmation && errors.passwordConfirmation)}
            fullWidth
            onBlur={handleBlur}
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <AdornmentIconButton
                  $isHidden={!values.passwordConfirmation}
                  onClick={() => setShowPasswordConfirmation(value => !value)}
                >
                  {showPasswordConfirmation ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </AdornmentIconButton>
              ),
            }}
          />
          {getError(touched, errors, actionCode) && (
            <FormHelperText><InfoIcon />{getError(touched, errors, actionCode)}</FormHelperText>
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={isSubmitting || !actionCode}
          >
            Reset Password
          </Button>
        </form>
      )}
    </Formik>
  );
}
