import { Dialog } from "@mui/material";

/**
 * Render closable popup dialog
 *
 * @param open - open/close state of popup
 * @param setOpen - callback to update open/close state
 * @param  children - JSX.Element
 * @return {*}  {JSX.Element}
 */
const PopupDialog = (props: {
  open: boolean;
  setOpen: (val: boolean) => void;
  children: JSX.Element;
}): JSX.Element => {
  return (
    <Dialog
      open={props.open}
      fullWidth={true}
      maxWidth={"lg"}
      aria-labelledby="form-dialog-title"
      onClose={() => props.setOpen(false)}
    >
      {props.children}
    </Dialog>
  );
};

export default PopupDialog;
