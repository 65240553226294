import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { ResetPasswordForm } from "../../../components/auth/chromeExtension/ResetPasswordForm";
import { Layout } from "../../../components/auth/chromeExtension/Layout";
import { BackLink, Button, BackNavLink } from "../../../components/auth/chromeExtension/Style";
import { ROUTE } from "../../../routes";
import { IS_PROD } from "../../../constants";

const SIGN_IN_URL = IS_PROD ? "https://www.compose.ai/login-4" : undefined;
const FORGOT_PASSWORD_URL = "/chrome-extension/forgot-password";

const ResetPassword: React.FC<unknown> = () => {
  const navigate = useNavigate();

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isCodeFailed, setIsCodeFailed] = useState(false);

  const goToSignIn = () => {
    navigate(ROUTE.SIGN_IN);
  }

  return (
    <Layout
      helmetTitle="Reset Password"
      title={isSubmitted ? "Password was reset" : "Choose your new password"}
      description={
        isSubmitted ?
          "Please sign in with the new password." :
          "Please enter a new password."
      }
    >
      {isSubmitted ? (
        <Button
          href={SIGN_IN_URL}
          type="button"
          fullWidth
          variant="contained"
          onClick={goToSignIn}
        >
          Back to Sign in
        </Button>
      ) : (
        <>
          <ResetPasswordForm
            onSubmitted={() => setIsSubmitted(true)}
            onCodeFailed={() => setIsCodeFailed(true)}
          />
          {IS_PROD && isCodeFailed && <BackLink href={FORGOT_PASSWORD_URL}>Back to Forgot password</BackLink>}
          {!IS_PROD && isCodeFailed && <BackNavLink to={ROUTE.FORGOT_PASSWORD}>Back to Forgot password</BackNavLink>}
        </>
      )}
    </Layout>
  );
};

export default ResetPassword;
