import React, { useEffect, useState, useCallback } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { fetchApiErrorMsg } from "../../utils/api";
import {
  Button as MuiButton,
  Alert as MuiAlert,
  Paper as MuiPaper,
  Divider as MuiDivider,
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField as MuiTextField,
  Autocomplete,
  LinearProgress,
  Typography as MuiTypography,
} from "@mui/material";
import { spacing, SpacingProps } from "@mui/system";
import useAuth from "../../hooks/useAuth";
import firebase from "firebase/app";
import SavedListDetails, { SavedResult } from "./ListDetails";

import * as Yup from "yup";
import { Formik } from "formik";
import { AuthUser } from "../../types/auth";
import { track, useTrack } from "../../hooks/useSegment";
import PopupDialog from "../../components/PopupDialog";
import { composeDemoLoomEmbedLink } from "../../constants";

const Button = styled(MuiButton)(spacing);

const Alert = styled(MuiAlert)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Divider = styled(MuiDivider)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

interface TypographyProps extends SpacingProps {
  component?: string;
}
const Typography = styled(MuiTypography)<TypographyProps>(spacing);

interface NoListUIProps {
  setOpen: (open: boolean) => void;
  open: boolean;
}
/**
 * NoSavedResultsUI is the fallback UI when there are no copy results saved by the logged in user
 * @required: setOpen - callback method to set the value of "open" used to toggle the rendering of VideoPopup component
 * @required: open - boolean value used to toggle the rendering of VideoPopup component
 */
const NoSavedResultsUI: React.FC<NoListUIProps> = ({ setOpen, open }) => {
  return (
    <>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{
          paddingTop: "10%",
          paddingBottom: "10%",
        }}
      >
        No copies here yet
        <Typography
          sx={{ fontSize: "14px", width: "360px", textAlign: "center" }}
        >
          Select a tool from the left sidebar and generate a new copy to liven
          the space up a little
        </Typography>
        <PopupDialog open={open} setOpen={setOpen}>
          <div
            style={{
              position: "relative",
              paddingBottom: "56.25%",
              height: 0,
            }}
          >
            <iframe
              title="See how it works"
              src={composeDemoLoomEmbedLink}
              frameBorder={0}
              allowFullScreen={true}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
            />
          </div>
        </PopupDialog>
        <Button
          sx={{
            marginTop: "24px",
            fontSize: "15px",
          }}
          variant="contained"
          color="primary"
          onClick={() => {
            track("Savedlist - See how it works", {
              action: "click",
              buttonType: "how-it-works",
            });
            setOpen(true);
          }}
        >
          See how it works
        </Button>
      </Grid>
    </>
  );
};

interface ISavedListState {
  /**
   * Name of the saved list
   */
  name: string;

  /**
   * This has been removed at the moment while creating a new saved list, but needs to support old list implementation
   */
  url?: string;

  /**
   * The firebase doc id of the saved list
   */
  id: string;

  /**
   * This contains the copy results that were saved under the current saved list
   */
  results: SavedResult[];
}

/** SavedLists is the component that displays the copy results saved by a user under different lists */
const SavedLists: React.FC<Record<string, never>> = () => {
  const [savedLists, setSavedLists] = useState<ISavedListState[]>([]);
  const [currentlySelectedList, setCurrentlySelectedList] = useState<
    ISavedListState | undefined
  >(undefined);
  const [isSubmitting, setIsSubmitting] = useState(true);
  const [open, setOpen] = useState(false);
  const [openNewList, setOpenNewList] = useState(false);
  const { user, savedResultsCount, addSavedResultsCount } = useAuth();
  const { track } = useTrack();
  const navigate = useNavigate();

  const removeSavedListElementById = useCallback(
    (resultId: string) => {
      setSavedLists((prevList) => {
        return prevList.map((savedList) => {
          const savedListResults = savedList.results;
          return {
            ...savedList,
            results: savedListResults.filter(
              (result) => result.id !== resultId
            ),
          };
        });
      });
      /** side-effect for removing 1 from savedResultsCount in local store */
      addSavedResultsCount(-1);
    },
    [addSavedResultsCount]
  );

  useEffect(() => {
    async function getSavedLists() {
      try {
        const newSavedLists: ISavedListState[] = [];
        await user?.doc
          ?.collection("projects")
          .get()
          .then(async (querySnapshot: firebase.firestore.QuerySnapshot) => {
            for (const doc of querySnapshot.docs) {
              await doc.ref
                .collection("savedResults")
                ?.get()
                .then(
                  (
                    resultsSnapshot: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
                  ) => {
                    const results: SavedResult[] = [];
                    resultsSnapshot.forEach((resultDoc) => {
                      results.push({
                        ...resultDoc.data(),
                        id: resultDoc.id,
                      } as SavedResult);
                    });
                    // these results exclude the soft deleted results under a saved list
                    const nonDeletedResults = results.filter(
                      (result) => !result.isDeleted
                    );

                    newSavedLists.push({
                      ...doc.data(),
                      results: nonDeletedResults,
                      id: doc.id,
                    } as ISavedListState);

                    setSavedLists(newSavedLists);
                  }
                );
            }
            setIsSubmitting(false);
          });
      } catch (e) {
        setIsSubmitting(false);
      }
    }
    if (user?.doc) getSavedLists();
  }, [user?.doc]);

  /** UI when a list is selected from lists filter */
  const markupForCurrentlySelected = useCallback(() => {
    if (currentlySelectedList && currentlySelectedList.results.length > 0) {
      return (
        <Grid
          item
          xs={12}
          lg={12}
          xl={12}
          key={`project-grid-${currentlySelectedList.id}`}
          sx={{ paddingTop: "0px !important" }}
        >
          <SavedListDetails
            savedList={currentlySelectedList}
            removeSavedListElementById={removeSavedListElementById}
          />
        </Grid>
      );
    } else {
      return <NoSavedResultsUI setOpen={setOpen} open={open} />;
    }
  }, [currentlySelectedList, open, removeSavedListElementById]);

  /** UI when there is no selection done in lists filter */
  const markupForAllLists = useCallback(() => {
    return savedLists.map(
      (list) =>
        list.results.length > 0 && (
          <Grid
            item
            xs={12}
            lg={12}
            xl={12}
            key={`project-grid-${list.id}`}
            sx={{ paddingTop: "0px !important" }}
          >
            <SavedListDetails
              savedList={list}
              removeSavedListElementById={removeSavedListElementById}
            />
          </Grid>
        )
    );
  }, [savedLists, removeSavedListElementById]);

  /** render saved results conditionally based on selection of lists filter */
  const showResultsBasedOnSelection = useCallback(() => {
    return currentlySelectedList
      ? markupForCurrentlySelected()
      : markupForAllLists();
  }, [currentlySelectedList, markupForAllLists, markupForCurrentlySelected]);

  return (
    <React.Fragment>
      <Helmet title="Saved Lists" />
      <Typography variant="h3" gutterBottom display="inline">
        Saved Lists
      </Typography>
      <Typography
        style={{
          marginTop: 10,
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/savedlists/edit")}
          style={{ fontSize: "1em", float: "right" }}
        >
          Edit lists
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpenNewList(true)}
          style={{ fontSize: "1em", float: "right", marginRight: "10px" }}
        >
          Create new list
        </Button>
        <CreateNewListDialog
          user={user}
          track={track}
          openNewList={openNewList}
          setOpenNewList={setOpenNewList}
          setSavedLists={setSavedLists}
        />
        <Autocomplete
          disablePortal
          blurOnSelect
          id="combo-box-demo"
          options={savedLists.map((list) => ({
            label: list.name,
            savedListId: list.id,
          }))}
          value={
            currentlySelectedList
              ? {
                  label: currentlySelectedList.name,
                  savedListId: currentlySelectedList.id,
                }
              : undefined
          }
          sx={{ width: 300 }}
          onChange={(e, value) => {
            if (value) {
              setCurrentlySelectedList(
                savedLists.find((list) => list.id === value.savedListId)
              );
            } else {
              setCurrentlySelectedList(undefined);
            }
          }}
          renderInput={(params) => (
            <TextField {...params} label="Filter by list" />
          )}
        />
      </Typography>

      <Divider my={6} />

      <Grid container alignItems="center">
        {isSubmitting ? (
          <LinearProgress />
        ) : (
          <Grid container spacing={6} sx={{ paddingTop: "24px" }}>
            {savedResultsCount && savedResultsCount > 0 ? (
              showResultsBasedOnSelection()
            ) : (
              <NoSavedResultsUI setOpen={setOpen} open={open} />
            )}
          </Grid>
        )}
      </Grid>
    </React.Fragment>
  );
};

/**
 * openNewList: decides whether we should render dialog in open/closed state
 * setOpenNewList: callback function for setting openNewList value
 * setSavedLists: function for setting the state savedLists
 */
interface CreateNewListDialogProps {
  openNewList: boolean;
  setOpenNewList: (open: boolean) => void;
  setSavedLists: (
    prevList: (prevList: ISavedListState[]) => ISavedListState[]
  ) => void;
  user: AuthUser;
  track: (event: string, properties: Record<string, unknown>) => void;
}

/**
 * CreateNewListDialog is the dialog component to create a new list.
 */
const CreateNewListDialog = ({
  openNewList,
  setOpenNewList,
  setSavedLists,
  user,
  track,
}: CreateNewListDialogProps) => {
  return (
    <Paper mt={4}>
      <div>
        <Dialog
          open={openNewList}
          aria-labelledby="form-dialog-title"
          onClose={() => setOpenNewList(false)}
        >
          <Grid container justifyContent="center">
            <DialogContent
              sx={{
                textAlign: "center",
              }}
            >
              <DialogTitle
                id="form-dialog-title"
                sx={{ fontSize: "34px", padding: 0, textAlign: "left" }}
              >
                Create a new list
              </DialogTitle>

              <Formik
                initialValues={{
                  savedList: "",
                  submit: false,
                }}
                validationSchema={Yup.object().shape({
                  savedList: Yup.string()
                    .max(255)
                    .required("List name is required"),
                })}
                onSubmit={async (
                  values,
                  { setErrors, setStatus, setSubmitting }
                ) => {
                  try {
                    const docId = await user?.doc.collection("projects").doc()
                      .id;
                    await user?.doc.collection("projects").doc(docId).set({
                      name: values.savedList,
                    });
                    track("Onboarding - Create new list", {
                      firstList: values.savedList,
                      action: "click",
                      buttonType: "create-new-list-button",
                    });
                    setSavedLists((prevList: ISavedListState[]) => {
                      const updatedSavedList = [
                        ...prevList,
                        {
                          name: values.savedList,
                          id: docId,
                          url: "",
                          results: [],
                        } as ISavedListState,
                      ];
                      return updatedSavedList;
                    });
                  } catch (error: any) {
                    const message = fetchApiErrorMsg(error);
                    setErrors({ submit: message });
                    setStatus({ success: false });
                    setSubmitting(false);
                  } finally {
                    setOpenNewList(false);
                  }
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values,
                }) => (
                  <form noValidate onSubmit={handleSubmit}>
                    {errors.submit && (
                      <Alert mt={2} mb={1} severity="warning">
                        {errors.submit}
                      </Alert>
                    )}
                    <TextField
                      sx={{ marginTop: "24px", marginBottom: "24px" }}
                      type="text"
                      name="savedList"
                      label="Enter new list name"
                      value={values.savedList}
                      error={Boolean(touched.savedList && errors.savedList)}
                      fullWidth
                      helperText={touched.savedList && errors.savedList}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                    >
                      CREATE LIST
                    </Button>
                  </form>
                )}
              </Formik>
            </DialogContent>
          </Grid>
        </Dialog>
      </div>
    </Paper>
  );
};
export default SavedLists;
