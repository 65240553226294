import styled from "styled-components/macro";

import Logo from "../vendor/assets/logo@2x.png";

const Brand = styled.img`
  width: 256px;
  height: 44px;
  margin-bottom: 32px;
`;
export default function BrandComponent() {
  return <Brand src={Logo} />;
}
