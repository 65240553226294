import React from 'react';
import { Link } from "react-router-dom";

import { ROUTE } from '../../../routes';

type LinkName = "signUp" | "forgotPassword" | "signIn";

type NavigationLinksProps = {
  links: LinkName[];
}

const NavigationLinks: React.FC<NavigationLinksProps> = (props) => {
  const { links } = props;

  return (
    <div className='auth-wrapper__nav-links'>
      {links.includes('signUp') && (
        <div>
          Don’t have an account?&nbsp;
          <Link to={ROUTE.SIGN_UP} className="auth-wrapper__blue-link">
            Sign up
          </Link>
        </div>
      )}
      {links.includes('forgotPassword') && (
        <div className="auth-wrapper__links">
          Having trouble signing in?&nbsp;
          <Link to={ROUTE.FORGOT_PASSWORD} className="auth-wrapper__blue-link">
            Forgot Password
          </Link>
        </div>
      )}
      {links.includes('signIn') && (
        <div className="auth-wrapper__links">
          Have an account?&nbsp;
          <Link to={ROUTE.SIGN_IN} className="auth-wrapper__blue-link">
            Log in
          </Link>
        </div>
      )}
    </div>
  );
}

export default NavigationLinks;
